import React, { useState } from "react";
import { Layout, Menu } from "antd";
import {
  HomeOutlined,
  ProjectOutlined,
  AppstoreOutlined,
  FileOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import logo from "../assets/logo.png";

import logoCollapsed from "../assets/logo-collapsed.png";

const { Sider } = Layout;

const SidebarLayout = ({ onCollapse }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    const newCollapsed = !collapsed;
    setCollapsed(newCollapsed);
    if (onCollapse) onCollapse(newCollapsed);
  };

  // Custom trigger component for top position
  const CustomTrigger = ({ collapsed, onClick }) => (
    <div
      onClick={onClick}
      style={{
        position: "absolute",
        right: -12,
        top: 24,
        width: 24,
        height: 24,
        background: "rgb(24, 32, 47)",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        cursor: "pointer",
        zIndex: 1,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        transition: "transform 0.3s",
        transform: collapsed ? "rotate(180deg)" : "none",
      }}
    >
      ◀
    </div>
  );

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={220}
      collapsedWidth={80}
      style={{
        backgroundColor: "rgb(24, 32, 47)",
        position: "relative",
        height: "100vh",
      }}
    >
      {/* Custom trigger at top */}
      <CustomTrigger collapsed={collapsed} onClick={toggleSidebar} />

      {/* Logo */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "16px",
          justifyContent: "center",
        }}
      >
        <img
          src={collapsed ? logoCollapsed : logo}
          alt="Logo"
          style={{ height: 32, transition: "all 0.2s" }}
        />
      </div>

      {/* Main Menu */}
      <Menu
        theme="dark"
        mode="inline"
        style={{
          backgroundColor: "rgb(24, 32, 47)",
          border: "none",
        }}
        items={[
          {
            key: "home",
            icon: <HomeOutlined />,
            label: "Home",
          },
          {
            key: "projects",
            icon: <ProjectOutlined />,
            label: "Projects",
          },
          {
            key: "modules",
            icon: <AppstoreOutlined />,
            label: "Modules",
            children: [
              {
                key: "physical-risk",
                label: "Physical Risk",
              },
              {
                key: "transition-risk",
                label: "Transition Risk",
              },
              {
                key: "real-estate",
                label: "Real Estate",
              },
            ],
          },
          {
            key: "documents",
            icon: <FileOutlined />,
            label: "Documents",
            children: [
              {
                key: "methodology",
                label: "Methodology",
              },
              {
                key: "apidocs",
                label: "APIDocs",
              },
              {
                key: "changelogs",
                label: "Changelogs",
              },
              {
                key: "faqs",
                label: "Faqs",
              },
            ],
          },
          {
            key: "settings",
            icon: <SettingOutlined />,
            label: "Settings",
          },
        ]}
      />

      {/* Bottom Menu */}
      <Menu
        theme="dark"
        mode="inline"
        style={{
          backgroundColor: "rgb(24, 32, 47)",
          position: "absolute",
          bottom: 0,
          width: "100%",
          border: "none",
        }}
        items={[
          {
            key: "help",
            icon: <QuestionCircleOutlined />,
            label: "Help",
          },
          {
            key: "logout",
            icon: <LogoutOutlined />,
            label: "Logout Account",
            style: { color: "#ff4d4f" },
          },
        ]}
      />
    </Sider>
  );
};

export default SidebarLayout;
