import React, { Component, createRef } from "react";
import {
  Table,
  Switch,
  Radio,
  Row,
  Col,
  Button,
  Checkbox,
  Select,
  Spin,
} from "antd";
import { Modal, Image } from "semantic-ui-react";
import { connect } from "react-redux";
import { locus } from "../actions";
import { getBoundsOfDistance } from "geolib";
import html2canvas from "html2canvas";
import maplogo from "../assets/map_logo.png";

import "./intensel_map.css";
/* eslint import/no-webpack-loader-syntax: off */

import mapboxgl from "!mapbox-gl";
import { tickStep, timeThursdays } from "d3";

mapboxgl.accessToken =
  //"pk.eyJ1IjoiYmFjay1vZmZpY2UtbWFuYWdlbWVudCIsImEiOiJjbGZxcmJlbjgwMWJxNDRwYjNpdXp0cGJ6In0.nfzF7xWo_S0Q90LhXBurxw";
  "pk.eyJ1IjoibGt0eWFnaSIsImEiOiJja2dhMmVrNGcwMzNxMnRrenhrbGh4YzBoIn0.h31ayIFQsUoW1YpL3LVkOQ";

class LegendControl {
  onAdd(map) {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl legend";
    this._container.id = "legendName";
    const paragraph = document.createElement("p");
    paragraph.textContent = "Flood in meters";
    paragraph.id = "legendTitle";
    paragraph.className = "legend-title"; // Optional: Add a class for custom styling
    this._container.appendChild(paragraph);
    const legendItems = [
      { color: "#336DFF", label: "0-0.25" },

      { color: "#33C2FF", label: "0.25-0.5" },
      { color: "#78FFC9", label: "0.5-0.75" },
      { color: "#E0FF55", label: "0.75-1" },
      { color: "#FFD600", label: "1-1.5" },
      { color: "#FF8700", label: "1.5-2" },
      { color: "#FF2F00", label: ">2" },
    ];

    legendItems.forEach((item) => {
      const legendItem = document.createElement("div");
      legendItem.className = "legend-item";

      const colorBox = document.createElement("span");
      colorBox.className = "legend-color";
      colorBox.style.backgroundColor = item.color;

      const label = document.createElement("span");
      label.className = "legend-label";
      label.textContent = item.label;

      legendItem.appendChild(colorBox);
      legendItem.appendChild(label);
      this._container.appendChild(legendItem);
    });

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
class DownloadMap extends Component {
  constructor(props) {
    super(props);
    this.mapContainer = React.createRef();

    this.state = {
      dataSource: [
        {
          key: "1",
          name: "City A",
          country_code: "CHN",
          rainfall_flood: 0.687032,
          gdp: 44.6213,
          populationDensity: 2788.9462,
          rainfall_flood_max: 2.72103,
          rainfall_flood_min: 0.30654,
        },
      ], // Initialized to an empty array
      address: "",
      modalVisible: false,
      options: "",
      duration_data: "",
      filterModalVisible: false,
      downloadModalVisible: false,
      filterType: "",
      selectedCity: null,
      mapStyle: "mapbox://styles/mapbox/satellite-v9",
      name: "",
      distance: "",
      country_code: "",
      scenario: "",
      year: "",
      columns: [],
      return_period: "",
      scenario_download: ["85"],
      year_download: ["2050"],
      latitude: "",
      longitude: "",
      yearOptions: [],
      token: "",
      rcpOptions: [],
      scale: "",
      is3DViewEnabled: false,
      floodDepth: null,
      isMapLoaded: false, // Track map loading status
      district: "", // New state for district name
      loading: false,
      isLoadingTiles: false,
      styleTransitionComplete: true,

      mapValueResponse: {
        hand: null,
        elevation: null,
        no_of_days: null,
        slope: null,
        flood: null,
        value: null,
      },
      variable: "",
      showTable: true, // Flag to control table visibility
      mapBounds: null, // State variable to hold map bounds
    };
    this.rasterLayerAdded = false;

    this.map = null;
    this.popup = null;
  }
  getDataForReturnPeriod = (data, returnPeriod, variable, scenario, year) => {
    console.log("datasource getting data", variable, scenario, year);

    // Initialize a result object with default values
    const result = {
      name: data.name,
      gdp: data.gdp,
      populationDensity: data.population,
      country_code: data.country_code,
      latitude: data.coordinates.latitude,
      longitude: data.coordinates.longitude,
    };

    if (variable === "rainfall_flood") {
      // Check if returnPeriod is available
      if (
        data.rainfall_flood &&
        data.rainfall_flood.average[returnPeriod] !== undefined
      ) {
        result.rainfall_flood_avg = data.rainfall_flood.average[returnPeriod];
        result.rainfall_flood_max = data.rainfall_flood.max[returnPeriod];
        result.rainfall_flood_min = data.rainfall_flood.min[returnPeriod];
      } else {
        console.warn("Invalid returnPeriod or missing rainfall_flood data.");
        result.rainfall_flood_avg = null;
        result.rainfall_flood_max = null;
        result.rainfall_flood_min = null;
      }
    } else if (variable === "storm_surge" && scenario && year) {
      const key = `${scenario}_${year}`;
      console.log("Looking for storm_surge data with key: ", key);

      // Check if key is available in storm_surge data
      if (data.storm_surge && data.storm_surge.average[key] !== undefined) {
        console.log("Storm surge data found for key:", key);
        console.log("storm_surge data: ", data.storm_surge);

        result.storm_surge_avg = data.storm_surge.average[key];
        result.storm_surge_max = data.storm_surge.max[key];
        result.storm_surge_min = data.storm_surge.min[key];
      } else {
        console.warn("Invalid scenario/year or missing storm_surge data.");
        result.storm_surge_avg = null;
        result.storm_surge_max = null;
        result.storm_surge_min = null;
      }
    }

    return result;
  };
  updateScale = () => {
    if (!this.map) return;

    const bounds = this.map.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    // Calculate the distance between the northeast and southwest corners
    const distance = this.getDistance(ne.lng, ne.lat, sw.lng, sw.lat);

    // Update the state with the new scale
    this.setState({ scale: `${(distance / 1000).toFixed(1)} km` }); // Convert to kilometers
  };

  // Haversine formula to calculate the distance between two lat/lng points
  getDistance = (lng1, lat1, lng2, lat2) => {
    const R = 6371000; // Radius of the Earth in meters
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lng2 - lng1) * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in meters
  };

  updateDataSource = (
    dataSource,
    apiResponse,
    returnPeriod,
    scenario,
    year
  ) => {
    console.log("Updating dataSource with:", {
      returnPeriod,
      scenario,
      year,
      dataSource,
    });

    const variable = apiResponse.portfolio_variables.includes("Storm Surge")
      ? "storm_surge"
      : "rainfall_flood";

    return dataSource.map((city) => {
      console.log("Getting data for city:", city.name);
      const newData = this.getDataForReturnPeriod(
        apiResponse,
        returnPeriod,
        variable,
        scenario,
        year
      );
      console.log("New data:", newData);
      return { ...city, ...newData };
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.name
    ) {
      let formdata = new FormData();
      formdata.append("name", this.props.location.state.name.name);

      this.setState({ name: this.props.location.state.name.name }, () => {
        this.props.generateMap(formdata);
      });
    }
    let token = localStorage.getItem("token");
    this.setState({ token: token });
    this.initializeMap();
  }

  componentDidUpdate(prevProps, prevState) {
    const { map_response, download_map_value } = this.props;

    // Check if download_map_value has changed
    if (prevProps.download_map_value !== download_map_value) {
      this.setState({ mapValueResponse: download_map_value }, () => {
        this.showPopup(this.state.longitude, this.state.latitude);
      });
    }

    // Check if mapBounds have changed
    if (prevState.mapBounds !== this.state.mapBounds && this.map) {
      const [[swLng, swLat], [neLng, neLat]] = this.state.mapBounds || [];
      if (!isNaN(swLng) && !isNaN(swLat) && !isNaN(neLng) && !isNaN(neLat)) {
        this.map.setMaxBounds(this.state.mapBounds);
      } else {
        console.error("Invalid map bounds: ", this.state.mapBounds);
      }
    }

    // Check if map_response has changed
    if (prevProps.map_response !== map_response) {
      console.log("map_response:", map_response);
      this.setState({ country_code: map_response.country_code });

      // Extract necessary data from map_response
      const durationData = map_response.duration_data || [];
      const returnPeriod = durationData[0] || [];
      const [scenario, year] = durationData[0]?.split("_") || [];
      const durationValues = map_response.duration_values || [];
      const distance = parseFloat(map_response.distance);

      // Update options, address, scenario, and year based on durationData
      const options = durationData.map((item) => ({
        label: item,
        value: item,
      }));

      this.setState(
        {
          address: map_response.address,
          options: durationValues,
          duration_data: durationData[0],
          return_period: returnPeriod || this.state.return_period,
          scenario: scenario || this.state.scenario,
          year: year || this.state.year,
          distance: !isNaN(distance)
            ? map_response.distance
            : this.state.distance,
        },
        () => {
          console.log("Updated options:", this.state.options);
          if (!isNaN(distance)) {
            localStorage.setItem("distance", this.state.distance);
            this.handleMapBounds(map_response);
          } else {
            console.error(
              "Invalid distance in map_response: ",
              map_response.distance
            );
          }
        }
      );

      // Determine the variable (storm_surge or rainfall_flood)
      const variable = map_response.portfolio_variables.includes("Storm Surge")
        ? "storm_surge"
        : "rainfall_flood";
      this.setState({ variable: variable }, () => {
        // Update dataSource based on variable
        this.updateDataSourceBasedOnVariable(variable, map_response);
        this.updateTableColumns(variable);
        this.updateRasterLayer();
      });
    }

    // Check if filter conditions (scenario, year, return_period) have changed
    if (
      prevState.scenario !== this.state.scenario ||
      prevState.year !== this.state.year ||
      prevState.return_period !== this.state.return_period
    ) {
      this.updateDataSourceBasedOnVariable(
        this.state.variable,
        this.props.map_response
      );
    }

    // Handle map style changes
    if (prevState.mapStyle !== this.state.mapStyle) {
      console.log("changing style");
      const currentCenter = this.map.getCenter();
      const currentZoom = this.map.getZoom();
      this.updateMapStyle();

      this.map.once("styledata", () => {
        this.map.setCenter(currentCenter);
        this.map.setZoom(currentZoom);
      });
    }
  }
  updateRasterLayer = () => {
    if (this.map) {
      if (this.map.getSource("wms-test-source")) {
        this.map.removeLayer("wms-test-layer");
        this.map.removeSource("wms-test-source");
      }
      this.addRasterLayer();
    }
  };
  handleMapStyleChange = (checked) => {
    const newStyle = checked
      ? "mapbox://styles/mapbox/dark-v10"
      : "mapbox://styles/mapbox/satellite-v9";

    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
    }

    this.setState(
      {
        mapStyle: newStyle,
        styleTransitionComplete: false,
        isLoadingTiles: true,
      },
      this.updateMapStyle
    );
  };

  updateMapStyle = () => {
    if (this.map) {
      const currentCenter = this.map.getCenter();
      const currentZoom = this.map.getZoom();
      const currentBearing = this.map.getBearing();
      const currentPitch = this.map.getPitch();

      // Clean up existing sources and layers
      this.cleanupLayers();

      // Set safety timeout
      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout);
      }
      this.loadingTimeout = setTimeout(() => {
        this.setState({
          isLoadingTiles: false,
          styleTransitionComplete: true,
        });
      }, 5000);

      this.map.setStyle(this.state.mapStyle, {
        diff: false, // Force a full style replacement
      });

      // Use the improved load event in Mapbox GL JS 2.6
      this.map.once("styledata", () => {
        // Ensure the style is fully loaded
        if (this.map.isStyleLoaded()) {
          this.onStyleLoaded(
            currentCenter,
            currentZoom,
            currentBearing,
            currentPitch
          );
        } else {
          // Wait for style to be fully loaded
          this.map.once("style.load", () => {
            this.onStyleLoaded(
              currentCenter,
              currentZoom,
              currentBearing,
              currentPitch
            );
          });
        }
      });
    }
  };

  onStyleLoaded = (center, zoom, bearing, pitch) => {
    this.setState({ styleTransitionComplete: true }, () => {
      console.log("zoom", zoom);
      // Restore map position
      this.map.setCenter(center);
      this.map.setZoom(zoom);
      this.map.setBearing(bearing);
      this.map.setPitch(pitch);

      // Add raster layer for both styles
      this.addRasterLayer();

      // Add 2D buildings for dark style if needed
      if (this.state.mapStyle.includes("dark")) {
        this.add2DBuildingsLayer();
      }
    });
  };

  cleanupLayers = () => {
    if (!this.map) return;

    try {
      const layerId = "wms-test-layer";
      const sourceId = "wms-test-source";

      if (this.map.getLayer(layerId)) {
        this.map.removeLayer(layerId);
      }
      if (this.map.getSource(sourceId)) {
        this.map.removeSource(sourceId);
      }
    } catch (error) {
      console.warn("Error during cleanup:", error);
    }
  };

  addRasterLayer = () => {
    if (!this.map || !this.state.styleTransitionComplete) {
      console.warn("Map or style not ready");
      return;
    }

    this.cleanupLayers();

    try {
      const { map_response } = this.props;
      const {
        variable,
        scenario,
        year,
        return_period,
        country_code,
      } = this.state;

      let baseUrl;
      if (variable === "storm_surge") {
        baseUrl = `https://devmap.intensel.net/storm_surge/tile/{x}/{y}/{z}?scenario_year=${scenario}_${year}`;
      } else if (country_code && variable === "rainfall_flood") {
        baseUrl = `https://devmap.intensel.net/rainfall_flood/tile/{x}/{y}/{z}?return_period=${return_period}yr&country_code=${country_code}`;
      } else {
        console.warn("Required parameters not available");
        this.setState({ isLoadingTiles: false });
        return;
      }

      const tileUrl =
        map_response.subregion && variable !== "storm_surge"
          ? `${baseUrl}&region_code=${map_response.subregion}`
          : baseUrl;

      // Add source
      this.map.addSource("wms-test-source", {
        type: "raster",
        tiles: [tileUrl],
        tileSize: 256,
        minzoom: 0,
        maxzoom: 22,
      });

      // Add layer with layout and paint properties
      this.map.addLayer({
        id: "wms-test-layer",
        type: "raster",
        source: "wms-test-source",
        layout: {
          visibility: "visible",
        },
        paint: {
          "raster-opacity": 0.8,
          "raster-resampling": "linear",
        },
      });

      // Setup tile loading events
      this.setupTileLoadingEvents();
    } catch (error) {
      console.error("Error adding raster layer:", error);
      this.setState({ isLoadingTiles: false });
    }
  };

  setupTileLoadingEvents = () => {
    if (!this.map) return;

    // Clear any existing timeouts
    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
    }

    // Set new safety timeout
    this.loadingTimeout = setTimeout(() => {
      this.setState({ isLoadingTiles: false });
    }, 5000);

    // Remove existing listeners
    this.map.off("data", this.handleTileData);

    // Add new data event listener
    this.map.on("data", this.handleTileData);
  };

  handleTileData = (e) => {
    if (e.sourceId === "wms-test-source") {
      if (e.dataType === "source") {
        // Update loading state based on isSourceLoaded
        const isSourceLoaded = this.map.isSourceLoaded("wms-test-source");

        if (isSourceLoaded) {
          this.setState({ isLoadingTiles: false });
          if (this.loadingTimeout) {
            clearTimeout(this.loadingTimeout);
          }
        } else {
          this.setState({ isLoadingTiles: true });
        }
      }
    }
  };
  // Separate method to update dataSource based on variable
  updateDataSourceBasedOnVariable(variable, map_response) {
    const { dataSource, return_period, scenario, year } = this.state;

    let updatedDataSource;
    if (variable === "rainfall_flood") {
      updatedDataSource = this.updateDataSource(
        dataSource,
        map_response,
        return_period,
        null,
        null
      );
    } else if (variable === "storm_surge") {
      updatedDataSource = this.updateDataSource(
        dataSource,
        map_response,
        null,
        scenario,
        year
      );
    }

    this.setState({ dataSource: updatedDataSource });
  }

  // Updated handleRCPChange function to update scenario, year, and return_period correctly
  handleRCPChange = (value) => {
    console.log("Selected value:", value);

    if (value.includes("_")) {
      const [scenario, year] = value.split("_");
      this.setState({
        scenario: scenario,
        year: year,
      });
    } else {
      this.setState({
        return_period: value,
      });
    }

    // Update duration_data based on selected value
    this.setState({ duration_data: value });
  };

  fetchDistrictName = async (latitude, longitude) => {
    const mapboxToken =
      "pk.eyJ1IjoibGt0eWFnaSIsImEiOiJja2dhMmVrNGcwMzNxMnRrenhrbGh4YzBoIn0.h31ayIFQsUoW1YpL3LVkOQ";
    const mapboxUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${mapboxToken}`;

    try {
      const response = await fetch(mapboxUrl);
      if (response.ok) {
        const data = await response.json();

        // Extract the first feature from the response, which typically has the most specific location information
        const fullPlaceName =
          data.features.length > 0
            ? data.features[0].place_name
            : "Unknown Location";
        const districtName = fullPlaceName.split(",")[0]; // Get the part before the first comma

        this.setState({ district: districtName });
      } else {
        console.error("Error fetching district name:", response.statusText);
        this.setState({ district: "Unknown Location" });
      }
    } catch (error) {
      console.error("Error fetching district name:", error);
      this.setState({ district: "Unknown Location" });
    }
  };

  handleMapBounds(map_response) {
    const { coordinates } = map_response;
    const { latitude, longitude } = coordinates || {};

    if (latitude && longitude && this.state.distance) {
      const distanceStr =
        this.state.distance || localStorage.getItem("distance");
      const distanceInMeters = parseInt(distanceStr) * 460;

      console.log(
        "Latitude:",
        latitude,
        "Longitude:",
        longitude,
        "Distance in meters:",
        distanceInMeters
      );

      const bounds = getBoundsOfDistance(
        { latitude, longitude },
        distanceInMeters
      );

      if (bounds && bounds.length === 2) {
        const mapBounds = [
          [bounds[0].longitude, bounds[0].latitude],
          [bounds[1].longitude, bounds[1].latitude],
        ];
        console.log("Calculated map bounds:", mapBounds);

        this.setState({ mapBounds, latitude, longitude }, () => {
          if (this.map) {
            console.log("jump map");
            this.map.jumpTo({
              center: [this.state.longitude, this.state.latitude],
              zoom: 15,
            });
            this.fetchDistrictName(latitude, longitude);
          }
        });
      } else {
        console.error("Invalid map bounds calculated:", bounds);
      }
    } else {
      console.error(
        "Invalid coordinates or distance:",
        coordinates,
        this.state.distance
      );
    }
  }

  componentWillUnmount() {
    if (this.map) {
      this.map.remove();
    }
    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
    }

    if (this.map) {
      this.map.off("data", this.handleTileData);
      this.map = null;
    }
  }

  initializeMap = () => {
    if (!this.mapContainer || !this.mapContainer.current) {
      console.error("Map container reference is not defined");
      return;
    }

    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: this.state.mapStyle,
      transformRequest: (url, resourceType) => {
        if (resourceType === "Tile" && url.includes("devmap.intensel.net")) {
          return {
            url: url,
            headers: {
              Authorization: `Token ${this.state.token}`,
            },
          };
        }
        return { url: url };
      },
      center: [this.state.longitude, this.state.latitude],
      maxBounds: this.state.mapBounds,
      pitch: 0,
      bearing: 0,
      pitchWithRotate: false,
      dragRotate: false,
      touchZoomRotate: false,
    });

    this.map.addControl(new mapboxgl.FullscreenControl());

    // Setup event listeners
    this.map.on("click", this.handleMapHover);
    this.map.on("zoom", this.updateScale);
    this.map.on("moveend", this.updateScale);

    // Handle map loading sequence
    this.map.on("load", () => {
      console.log("Map load event triggered");

      // Wait for style to be fully loaded
      if (!this.map.isStyleLoaded()) {
        console.log("Waiting for style to load...");
        this.map.once("styledata", this.initializeMapFeatures);
      } else {
        console.log("Style already loaded, initializing features...");
        this.initializeMapFeatures();
      }

      // Add power plant layer
      this.map.loadImage(
        "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
        (error, image) => {
          if (error) throw error;
          this.map.addImage("custom-marker", image);
        }
      );

      this.map.addSource("power_plants", {
        type: "vector",
        tiles: [
          "https://devmap.intensel.net/power_plants/tile/{x}/{y}/{z}.pbf",
        ],
        minzoom: 0,
        maxzoom: 22,
      });

      this.map.addLayer({
        id: "power-plants-layer",
        type: "symbol",
        source: "power_plants",
        "source-layer": "power_plants",
        layout: {
          "icon-image": "custom-marker",
          "icon-size": 1,
          "icon-allow-overlap": true, // Prevents icon clipping
          "icon-anchor": "bottom",
        },
        paint: {
          "icon-color": [
            "match",
            ["get", "id"],
            "special-id",
            "#ff0000",
            "#00ff00",
          ],
        },
      });

      this.map.on("click", "power-plants-layer", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const properties = e.features[0].properties;

        const description =
          "<strong>" +
          (properties.name || "Unknown Name") +
          "</strong><br>" +
          "Type: " +
          (properties.type || "N/A") +
          "<br>" +
          "Average Generation: " +
          (properties.capacity || "N/A") +
          " MW";

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(this.map);
      });

      this.map.on("mouseenter", "power-plants-layer", () => {
        this.map.getCanvas().style.cursor = "pointer";
      });

      this.map.on("mouseleave", "power-plants-layer", () => {
        this.map.getCanvas().style.cursor = "";
      });

      const centerMarkerElement = document.createElement("div");
      centerMarkerElement.className = "center-marker";
      centerMarkerElement.style.backgroundImage =
        "url('https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png')";
      centerMarkerElement.style.width = "40px";
      centerMarkerElement.style.height = "40px";
      centerMarkerElement.style.backgroundSize = "contain";
      centerMarkerElement.style.zIndex = "9999"; // Highest z-index
      centerMarkerElement.style.position = "absolute";

      // Add the DOM marker
      new mapboxgl.Marker({ element: centerMarkerElement })
        .setLngLat([this.state.longitude, this.state.latitude]) // Set to map center
        .addTo(this.map);
    });
  };

  initializeMapFeatures = () => {
    console.log("Initializing map features...");

    // Add layers and controls
    this.addRasterLayer();
    console.log("Raster layer added");

    this.addLegendControl();
    console.log("Legend control added");

    // Set map as loaded and then set initial scale
    this.setState({ isMapLoaded: true }, () => {
      console.log("Map loaded, setting initial scale...");
      // Add a small delay to ensure all layers are fully rendered
      setTimeout(() => {
        this.setInitialScale();
        console.log("Initial scale set");
      }, 100);
    });
  };

  setInitialScale = () => {
    if (!this.map || !this.state.distance) {
      console.warn("Map or distance not available");
      return;
    }

    // Target distance in meters
    const targetDistance = this.state.distance * 1000;
    const center = this.map.getCenter();

    // Binary search for the correct zoom level
    let minZoom = 0;
    let maxZoom = 22;
    let currentZoom = 12;

    const getMapDistance = (zoom) => {
      this.map.setZoom(zoom);
      const bounds = this.map.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      return this.getDistance(ne.lng, ne.lat, sw.lng, sw.lat);
    };

    // Perform binary search for up to 6 iterations
    for (let i = 0; i < 6; i++) {
      const currentDistance = getMapDistance(currentZoom);
      console.log(
        `Iteration ${i}: zoom ${currentZoom}, distance ${currentDistance /
          1000}km (target: ${targetDistance / 1000}km)`
      );

      // If we're within 10% of target distance, we're done
      if (Math.abs(currentDistance - targetDistance) / targetDistance < 0.1) {
        break;
      }

      if (currentDistance > targetDistance) {
        minZoom = currentZoom;
        currentZoom = (currentZoom + maxZoom) / 2;
      } else {
        maxZoom = currentZoom;
        currentZoom = (currentZoom + minZoom) / 2;
      }
    }

    // Set final zoom and update scale
    this.map.setZoom(currentZoom);
    this.updateScale();

    console.log(
      `Final zoom set to ${currentZoom} for target distance ${this.state.distance}km`
    );
  };

  add3DBuildingsLayer = () => {
    const layers = this.map.getStyle().layers;
    const labelLayerId = layers.find(
      (layer) => layer.type === "symbol" && layer.layout["text-field"]
    ).id;

    if (this.map.getLayer("add-3d-buildings")) {
      return; // If the layer already exists, do nothing
    }

    this.map.addLayer(
      {
        id: "add-3d-buildings",
        source: "composite",
        "source-layer": "building",
        filter: ["==", "extrude", "true"],
        type: "fill-extrusion",
        minZoom: 16,
        paint: {
          "fill-extrusion-color": "#ffffff",
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "min_height"],
          ],
          "fill-extrusion-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0, // Opacity is zero below zoom level 15
            15.05,
            0.6,
          ],
        },
      },
      labelLayerId
    );
  };
  add2DBuildingsLayer = () => {
    const layers = this.map.getStyle().layers;
    const labelLayerId = layers.find(
      (layer) => layer.type === "symbol" && layer.layout["text-field"]
    ).id;

    if (this.map.getLayer("building-2d")) {
      return; // If the layer already exists, do nothing
    }

    this.map.addLayer(
      {
        id: "building-2d",
        source: "composite",
        "source-layer": "building",
        type: "fill",
        minzoom: 15, // Ensure visibility at all zoom levels

        paint: {
          "fill-color": "#d6d6d6",
          "fill-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0.2, // At zoom level 14, fill-opacity is 0.6
            15,
            0.6, // At higher zoom levels, opacity increases slightly for clarity
          ],
        },
      },
      labelLayerId
    );
  };
  remove2DBuildingsLayer = () => {
    if (this.map.getLayer("building-2d")) {
      this.map.removeLayer("building-2d");
      this.map.removeSource("composite");
    }
  };

  remove3DBuildingsLayer = () => {
    if (this.map.getLayer("add-3d-buildings")) {
      this.map.removeLayer("add-3d-buildings");
      this.map.removeSource("composite");
    }
  };

  handle3DViewChange = (checked) => {
    this.setState({ is3DViewEnabled: checked }, () => {
      if (this.state.is3DViewEnabled) {
        this.add3DBuildingsLayer();
        this.remove2DBuildingsLayer();
      } else {
        this.remove3DBuildingsLayer();
        this.add2DBuildingsLayer();
      }
    });
  };

  addLegendControl = () => {
    console.log("legend");
    const legend = new LegendControl();
    this.map.addControl(legend, "bottom-right");
  };
  handleMapHover = (e) => {
    const longitude = e.lngLat.lng;
    const latitude = e.lngLat.lat;
    const country_code = this.state.dataSource[0]?.country_code || "";
    const { map_response } = this.props;

    console.log("Map hover coordinates:", { longitude, latitude });

    let formdata = new FormData();
    formdata.append("latitude", latitude);
    formdata.append("longitude", longitude);
    formdata.append("country_code", country_code);
    formdata.append("duration_data", this.state.duration_data);
    formdata.append("variable", this.state.variable);
    if (map_response.subregion != null) {
      formdata.append("subregion", map_response.subregion);
    }

    this.props.MapValue(formdata);

    this.setState({ longitude, latitude }, () => {
      console.log("State updated with new coordinates:", {
        longitude,
        latitude,
      });
      // this.showPopup(longitude, latitude);
    });
  };

  // Separate function to show the popup
  showPopup = (lng, lat) => {
    const {
      hand,
      elevation,
      no_of_days,
      slope,
      flood,
      value,
    } = this.state.mapValueResponse;
    const hazard =
      this.state.variable === "rainfall_flood" ? "Flood" : "Storm Surge";

    console.log("Popup data:", {
      hand,
      elevation,
      no_of_days,
      slope,
      flood,
      value,
    });

    if (this.popup) {
      this.popup.remove();
    }

    if (this.map && hand != null && elevation != null) {
      new mapboxgl.Popup()
        .setLngLat([lng, lat])
        .setHTML(
          `${hazard} (in m): ${value || "0"}<br/>
                Elevation (in m): ${elevation || "0"}<br/>
                Hand: ${hand || "0"}<br/>
                Number of Days: ${no_of_days || "0"}<br/>
                Slope: ${slope || "0"} %;`
        )
        .addTo(this.map);
    } else {
      console.error("Popup data is missing or map is not initialized");
    }
  };

  removePopup = () => {
    this.popup.remove();
  };

  handleAnalyse = (record) => {
    this.setState({ selectedCity: record, modalVisible: true });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  };

  getRainfallFloodColumns = () => {
    return [
      { title: "Country Code", dataIndex: "country_code", key: "country_code" },

      {
        title: "Population Density (per sqkm)",
        dataIndex: "populationDensity",
        key: "populationDensity",
        render: (text) => (text ? text.toFixed(2) + " people/sqkm" : "0"),
      },
      {
        title: "Rainfall Flood (in m)",
        dataIndex: "rainfall_flood_avg",
        key: "rainfall_flood_avg",
        render: (text) => (text ? text.toFixed(2) : "0"),
      },

      {
        title: "Rainfall Flood Max (in m)",
        dataIndex: "rainfall_flood_max",
        key: "rainfall_flood_max",
        render: (text) => (text ? text.toFixed(2) : "0"),
      },
      {
        title: "Rainfall Flood Min (in m)",
        dataIndex: "rainfall_flood_min",
        key: "rainfall_flood_min",
        render: (text) => (text ? text.toFixed(2) : "0"),
      },
      // Add more columns as needed...
    ];
  };

  getStormSurgeColumns = () => {
    return [
      { title: "Country Code", dataIndex: "country_code", key: "country_code" },
      {
        title: "Population Density (per sqkm)",
        dataIndex: "populationDensity",
        key: "populationDensity",
        render: (text) => (text ? text.toFixed(2) + " people/sqkm" : "0"),
      },
      {
        title: "Storm Surge (in m)",
        dataIndex: "storm_surge_avg",
        key: "storm_surge",
        render: (text) => (text ? text.toFixed(2) : "0"),
      },

      {
        title: "Storm Surge Max (in m)",
        dataIndex: "storm_surge_max",
        key: "storm_surge_max",
        render: (text) => (text ? text.toFixed(2) : "0"),
      },
      {
        title: "Storm Surge Min (in m)",
        dataIndex: "storm_surge_min",
        key: "storm_surge_min",
        render: (text) => (text ? text.toFixed(2) : "0"),
      },
      // Add more columns as needed...
    ];
  };

  updateTableColumns = (variable) => {
    let columns;
    if (variable === "rainfall_flood") {
      columns = this.getRainfallFloodColumns();
    } else if (variable === "storm_surge") {
      columns = this.getStormSurgeColumns();
    }

    this.setState({ columns });
  };

  handlePeriodChange = (e) => {
    const newPeriod = e.target.value;
    this.setState({ return_period: newPeriod }, () => {
      this.updateDataSource(
        this.state.dataSource,
        this.props.map_response,
        newPeriod
      );
      this.updateRasterLayer();
    });
  };

  handleScenarioChange = (checkedValues) => {
    this.setState({ selectedScenarios: checkedValues });
  };
  handleDownload = () => {
    let formdata = new FormData();
    formdata.append("project_name", this.state.name);

    //formdata.append("scenarios", JSON.stringify(this.state.scenario_download));
    //formdata.append("years", JSON.stringify(this.state.year_download));
    this.props.downloadMap(formdata);
  };

  addLegendToMap = () => {
    const legend = document.createElement("div");
    legend.className = "legend";

    const legendItems = [
      { color: "#faffff", label: "0-0.1" },
      { color: "#d3fad3", label: "0.1-0.2" },
      { color: "#acf5a8", label: "0.2-0.5" },
      { color: "#56c5b8", label: "0.5-0.75" },
      { color: "#0096c8", label: "0.75-1" },
      { color: "#00507d", label: "1-1.5" },
      { color: "#000a32", label: "1.5-2" },
      { color: "#000519", label: ">2" },
    ];

    legendItems.forEach((item) => {
      const legendItem = document.createElement("div");
      legendItem.className = "legend-item";

      const legendColor = document.createElement("div");
      legendColor.className = "legend-color";
      legendColor.style.backgroundColor = item.color;

      const legendLabel = document.createElement("div");
      legendLabel.className = "legend-label";
      legendLabel.textContent = item.label;

      legendItem.appendChild(legendColor);
      legendItem.appendChild(legendLabel);
      legend.appendChild(legendItem);
    });

    // Add the legend to the map container
    this.map.getContainer().appendChild(legend);
  };
  /*handleExportImage = () => {
    const { name } = this.state;
    const mapContainerId = "capture";
    const increasedWidth = 1800;
    const increasedHeight = 1800;
    const logoElementId = "mapLogo";
    const fullscreenWidgetClass = "mapboxgl-ctrl-fullscreen";
    const scaleElementId = "scale"; // Element to hide during export

    const loadDelay = 10000; // Adjust the delay time in milliseconds as needed

    // Function to format the current date and time
    const getFormattedTimestamp = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    };

    const mapElement = document.getElementById(mapContainerId);
    const logoElement = document.getElementById(logoElementId);
    const fullscreenWidget = document.querySelector(
      `.${fullscreenWidgetClass}`
    );
    const scaleElement = document.getElementById(scaleElementId); // Get scale element

    if (this.mapContainer.current) {
      const initialWidth = this.mapContainer.current.style.width;
      const initialHeight = this.mapContainer.current.style.height;

      this.mapContainer.current.style.width = `${increasedWidth}px`;
      this.mapContainer.current.style.height = `${increasedHeight}px`;

      this.map.resize();

      setTimeout(() => {
        this.map.flyTo({
          center: [this.state.longitude, this.state.latitude],
          zoom: 15,
          speed: 4,
        });

        const waitForMap = new Promise((resolve) => {
          this.map.once("moveend", resolve);
        });

        waitForMap.then(() => {
          // Show the logo and hide the scale
          if (logoElement) {
            logoElement.style.display = "block";
          }
          if (fullscreenWidget) {
            fullscreenWidget.style.display = "none";
          }
          if (scaleElement) {
            scaleElement.style.display = "none";
          }

          html2canvas(mapElement, {
            useCORS: true,
            backgroundColor: null,
            width: increasedWidth,
            height: increasedHeight,
          })
            .then((canvas) => {
              const link = document.createElement("a");
              const timestamp = getFormattedTimestamp();
              link.href = canvas.toDataURL("image/png");
              link.download = `${name}_${timestamp}.png`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              // Hide the logo and show the scale again
              if (logoElement) {
                logoElement.style.display = "none";
              }
              if (fullscreenWidget) {
                fullscreenWidget.style.display = "block";
              }
              if (scaleElement) {
                scaleElement.style.display = "block";
              }

              // After downloading, reset map container dimensions
              this.mapContainer.current.style.width = initialWidth;
              this.mapContainer.current.style.height = initialHeight;
              this.map.resize();
            })
            .catch((error) => {
              console.error("html2canvas error: ", error);

              // Hide the logo and show the scale again in case of error
              if (logoElement) {
                logoElement.style.display = "none";
              }
              if (fullscreenWidget) {
                fullscreenWidget.style.display = "block";
              }
              if (scaleElement) {
                scaleElement.style.display = "block";
              }

              // Reset map container dimensions on error
              this.mapContainer.current.style.width = initialWidth;
              this.mapContainer.current.style.height = initialHeight;
              this.map.resize();
            });
        });
      }, loadDelay);
    } else {
      console.error("Map container ref not found.");
    }
  };*/

  handleExportImage = () => {
    const { address, latitude, longitude } = this.state;
    const mapContainerId = "capture";
    const increasedWidth = 1850;
    const increasedHeight = 1850;
    const logoElementId = "mapLogo";
    const fullscreenWidgetClass = "mapboxgl-ctrl-fullscreen";
    const scaleElementId = "scale";
    const legendElementId = "legendName";
    const nameElementId = "nameElement";
    const geoLocationId = "geolocation";
    const legendTitleId = "legendTitle";
    const originalZoom = this.map.getZoom();

    const loadDelay = 15000;
    const captureDelay = 2000; // Adjust the delay time in milliseconds as needed

    const getFormattedTimestamp = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    };

    const mapElement = document.getElementById(mapContainerId);
    const logoElement = document.getElementById(logoElementId);
    const fullscreenWidget = document.querySelector(
      `.${fullscreenWidgetClass}`
    );
    const nameElement = document.getElementById(nameElementId);
    const geoElement = document.getElementById(geoLocationId);
    const scaleElement = document.getElementById(scaleElementId);
    const legendElement = document.getElementById(legendElementId);
    const legendTitleElement = document.getElementById(legendTitleId);

    this.setState({ loading: true });

    if (this.mapContainer.current) {
      const initialWidth = this.mapContainer.current.style.width;
      const initialHeight = this.mapContainer.current.style.height;

      const initialLogoSize = logoElement ? logoElement.style.width : null;
      const initialLegendFontSize = legendElement
        ? legendElement.style.fontSize
        : null;
      const initialLegendSize = legendElement
        ? { ...legendElement.style }
        : null;
      const initialLegendTitleFontSize = legendTitleElement
        ? legendTitleElement.style.fontSize
        : null;

      // Store initial styles for legend child elements
      const initialLegendLabelStyles = [];
      const legendLabels = Array.from(
        legendElement.getElementsByClassName("legend-label")
      );
      legendLabels.forEach((child) => {
        initialLegendLabelStyles.push({
          fontSize: child.style.fontSize,
          padding: child.style.padding,
        });
      });

      this.mapContainer.current.style.width = `${increasedWidth}px`;
      this.mapContainer.current.style.height = `${increasedHeight}px`;

      if (logoElement) {
        logoElement.style.width = "200px"; // Increased logo size
      }

      if (legendElement) {
        //legendElement.style.border = "2px solid black";
        legendElement.style.width = "200px"; // Increase width
        legendElement.style.height = "auto"; // Adjust height based on content
        legendElement.style.backgroundColor = "#f0f0f0"; // Ensure background grows with font

        // Apply increased styles to legend child elements
        legendLabels.forEach((child) => {
          child.style.fontSize = "24px";
          child.style.padding = "5px";
        });
      }
      console.log("Title", legendTitleElement);

      if (legendTitleElement) {
        legendTitleElement.style.fontSize = "24px"; // Ensure font size is applied
      }

      if (geoElement && nameElement) {
        const geoParagraph = geoElement.querySelector("p");
        const nameParagraph = nameElement.querySelector("p");

        if (geoParagraph) {
          geoParagraph.style.fontSize = "26px";
          geoParagraph.style.paddingTop = "0px";
          geoParagraph.style.marginBottom = "0px";

          // Set font size for geoElement's paragraph
        }
        if (nameParagraph) {
          nameParagraph.style.fontSize = "26px";
          nameParagraph.style.paddingTop = "0px";
          nameParagraph.style.marginBottom = "0px"; // Set font size for nameElement's paragraph
          // Set font size for nameElement's paragraph
        }

        // Center the text horizontally and vertically
        geoElement.style.display = "flex";
        geoElement.style.alignItems = "center";
        geoElement.style.justifyContent = "center";
        geoElement.style.textAlign = "center";

        nameElement.style.display = "flex";
        nameElement.style.alignItems = "center";
        nameElement.style.justifyContent = "center";
        nameElement.style.textAlign = "center";

        //geoElement.style.border = "2px solid black";
        //nameElement.style.border = "2px solid black";
        geoElement.style.opacity = "0.75";
        nameElement.style.opacity = "0.75";

        geoElement.style.backgroundColor = "#f0f0f0";
        nameElement.style.backgroundColor = "#f0f0f0";

        geoElement.style.height = "auto";
        nameElement.style.height = "auto";
      }

      this.map.resize();
      this.map.fitBounds(
        [
          [longitude - 0.1, latitude - 0.1], // Southwest coordinates
          [longitude + 0.1, latitude + 0.1], // Northeast coordinates
        ],
        {
          padding: 50,
          maxZoom: 12, // Adjust max zoom if needed
        }
      );

      setTimeout(() => {
        this.map.flyTo({
          center: [this.state.longitude, this.state.latitude],
          zoom: 12,
          speed: 5,
        });

        const waitForMap = new Promise((resolve) => {
          this.map.once("moveend", resolve);
        });

        waitForMap.then(() => {
          if (logoElement) {
            logoElement.style.display = "block";
          }
          if (fullscreenWidget) {
            fullscreenWidget.style.display = "none";
          }
          if (scaleElement) {
            scaleElement.style.display = "none";
          }

          html2canvas(mapElement, {
            useCORS: true,
            backgroundColor: null,
            width: increasedWidth,
            height: increasedHeight,
          })
            .then((canvas) => {
              const link = document.createElement("a");
              const timestamp = getFormattedTimestamp();
              link.href = canvas.toDataURL("image/png");
              link.download = `${address}_${timestamp}.png`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              if (logoElement) logoElement.style.display = "none";
              if (legendElement) {
                legendElement.style.border = "none";

                legendElement.style.width = initialLegendSize.width; // Reset width
                legendElement.style.height = initialLegendSize.height; // Reset height
                legendElement.style.fontSize = initialLegendFontSize;

                // Reset legend child elements
                legendLabels.forEach((child, index) => {
                  child.style.fontSize =
                    initialLegendLabelStyles[index].fontSize;
                  child.style.padding = initialLegendLabelStyles[index].padding;
                });
              }

              if (legendTitleElement) {
                legendTitleElement.style.fontSize = initialLegendTitleFontSize;
              }

              if (geoElement && nameElement) {
                const geoParagraph = geoElement.querySelector("p");
                const nameParagraph = nameElement.querySelector("p");

                if (geoParagraph) {
                  geoParagraph.style.paddingTop = "0px"; // Set font size for geoElement's paragraph
                  geoParagraph.style.paddingBottom = "0px";
                  geoParagraph.style.marginBottom = "0px";

                  // Set font size for geoElement's paragraph

                  geoParagraph.style.fontSize = initialLegendFontSize; // Set font size for geoElement's paragraph
                }
                if (nameParagraph) {
                  nameParagraph.style.paddingTop = "0px"; // Set font size for geoElement's paragraph
                  nameParagraph.style.paddingBottom = "0px"; // Set font size for geoElement's paragraph
                  nameParagraph.style.marginBottom = "0px"; // Set font size for geoElement's paragraph

                  nameParagraph.style.fontSize = initialLegendFontSize; // Set font size for nameElement's paragraph
                }
                geoElement.style.fontSize = initialLegendFontSize;
                nameElement.style.fontSize = initialLegendFontSize;
                geoElement.style.border = "none";
                nameElement.style.border = "none";
                geoElement.style.opacity = "0.75";
                nameElement.style.opacity = "0.75";
              }

              if (fullscreenWidget) {
                fullscreenWidget.style.display = "block";
              }
              if (scaleElement) {
                scaleElement.style.display = "block";
              }

              setTimeout(() => {
                this.mapContainer.current.style.width = initialWidth;
                this.mapContainer.current.style.height = initialHeight;
                this.map.resize();

                this.setState({ loading: false, downloadModalVisible: false });
              }, 2000);
            })
            .catch((error) => {
              console.error("html2canvas error: ", error);

              if (logoElement) logoElement.style.width = initialLogoSize;
              if (legendElement) {
                legendElement.style.width = initialLegendSize.width;
                legendElement.style.height = initialLegendSize.height;
                legendElement.style.fontSize = initialLegendFontSize;

                // Reset legend child elements on error
                legendLabels.forEach((child, index) => {
                  child.style.fontSize =
                    initialLegendLabelStyles[index].fontSize;
                  child.style.padding = initialLegendLabelStyles[index].padding;
                });
              }

              this.mapContainer.current.style.width = initialWidth;
              this.mapContainer.current.style.height = initialHeight;
              this.map.resize();

              this.setState({ loading: false, downloadModalVisible: false });
            });
          this.map.flyTo({
            center: [longitude, latitude],
            zoom: originalZoom,
            speed: 2,
          });
        });
      }, loadDelay + captureDelay);
    } else {
      console.error("Map container ref not found.");
      this.setState({ loading: false });
    }
  };

  handleYearChange = (e) => {
    this.setState({ year: e.target.value });
  };

  handleYearChange_download = (checkedValues) => {
    this.setState({ year_download: checkedValues });
  };
  handleRCPChange_download = (checkedValues) => {
    this.setState({ scenario_download: checkedValues });
  };

  openFilterModal = () => {
    this.setState({ filterModalVisible: true });
  };

  closeFilterModal = () => {
    this.setState({ filterModalVisible: false });
  };
  openDownloadModal = () => {
    this.setState({ downloadModalVisible: true });
  };

  closeDownloadModal = () => {
    this.setState({ downloadModalVisible: false });
  };

  applyFilter = () => {
    // Handle the filter application logic based on the filterType
    if (this.state.variable === "storm_surge") {
      console.log(
        "applying filter",
        this.state.variable,
        this.state.year,
        this.state.scenario
      );
      const { scenario, year } = this.state;
      const updatedDataSource = this.updateDataSource(
        this.state.dataSource,
        this.props.map_response,
        scenario,
        year
      );
      this.setState({ dataSource: updatedDataSource }, this.updateRasterLayer);
    } else if (this.state.variable === "rainfall_flood") {
      const { return_period } = this.state;
      // Assuming you have the logic to update the dataSource based on return_period
      const updatedDataSource = this.updateDataSource(
        this.state.dataSource,
        this.props.map_response,
        return_period
      );
      this.setState({ dataSource: updatedDataSource }, this.updateRasterLayer);
    }

    this.setState({ filterModalVisible: false });
  };

  // Update filter type
  handleFilterTypeChange = (e) => {
    this.setState({ filterType: e.target.value });
  };

  handleTableVisibility = (checked) => {
    this.setState({ showTable: checked });
  };

  render() {
    return (
      <div className="custom-table" style={{ marginTop: "3.2%" }}>
        <Button
          type="primary"
          onClick={this.openFilterModal}
          style={{
            float: "right",
            color: "black",
            backgroundColor: "rgb(255,171,0)",
            marginBottom: "16",
            fontFamily: "montserrat",
            border: "0px",
            marginLeft: "1%",
            fontWeight: "600",
          }}
        >
          Filter Options
        </Button>
        <Button
          type="primary"
          onClick={this.openDownloadModal}
          style={{
            float: "right",
            color: "black",
            backgroundColor: "rgb(255,171,0)",
            marginBottom: "16",
            fontFamily: "montserrat",
            border: "0px",
            marginLeft: "1%",
            fontWeight: "600",
          }}
        >
          Download
        </Button>

        <br />
        <br />
        {this.state.showTable && (
          <div>
            <Table
              dataSource={this.state.dataSource}
              columns={this.state.columns}
              bordered
              pagination={false}
            />
            <br />
          </div>
        )}

        <Switch
          checkedChildren="Street View"
          unCheckedChildren="Satellite View"
          onChange={this.handleMapStyleChange}
          style={{ marginBottom: 16 }}
        />
        {this.state.mapStyle.includes("dark") && (
          <Switch
            checkedChildren="3D"
            unCheckedChildren="2D"
            checked={this.state.is3DViewEnabled}
            onChange={this.handle3DViewChange}
            style={{ marginTop: "-15px", marginLeft: "5px" }}
          />
        )}

        {/* {(this.state.isLoadingTiles || !this.state.styleTransitionComplete) && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              padding: "20px",
              borderRadius: "8px",
              zIndex: 1000,
            }}
          >
            <Spin tip="Loading map tiles..." />
          </div>
          )}*/}

        <Switch
          className="hide"
          checkedChildren="Show Table"
          unCheckedChildren="Hide Table"
          onChange={this.handleTableVisibility}
          checked={this.state.showTable} // Make sure it's tied to state
          style={{ marginBottom: 16, float: "right" }}
        />

        <div style={{ position: "relative" }} id="capture">
          {/* Hidden Map Logo */}
          <Image
            id="mapLogo"
            src={maplogo}
            alt="Logo"
            style={{
              display: "none",
              position: "absolute",
              top: "10px",
              right: "50px",
              width: "100px",
              zIndex: 1000, // Ensure it is above the map
            }}
          />

          {/* Loader 
          {!this.state.isMapLoaded && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
              }}
            >
              <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            </div>
            )}*/}

          {/* Map Container and Controls */}

          <div
            ref={this.mapContainer}
            style={{
              width: "100%",
              height: "800px",
            }}
          />

          {/* Scale Display */}
          <div
            id="scale"
            style={{
              position: "absolute",
              bottom: 10,
              left: 10,
              background: "white",
              padding: "5px",
              borderRadius: "3px",
            }}
          >
            Scale: {this.state.scale}
          </div>

          {/* Info Box for Storm Surge or Rainfall Flood */}
          <div
            id="nameElement"
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "5px",
              // boxShadow: "0 0 10px rgba(0,0,0,0.5)",
              opacity: "0.75",
            }}
          >
            {this.state.variable === "storm_surge" ? (
              <p>
                Storm Surge {this.state.scenario / 10} Year {this.state.year}{" "}
                {this.state.distance} km
              </p>
            ) : (
              <p>
                Rainfall Flood 1:{this.state.return_period} Return Period{" "}
                {this.state.distance} km
              </p>
            )}
          </div>
          <div
            id="geolocation"
            style={{
              position: "absolute",
              bottom: 50, // Just below the existing info box
              left: 10,
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "5px",
              //boxShadow: "0 0 10px rgba(0,0,0,0.5)",
              opacity: "0.75",
            }}
          >
            {this.state.latitude && this.state.longitude ? (
              <p>
                Geolocation: {this.state.latitude.toFixed(5)},&nbsp;
                {this.state.longitude.toFixed(5)}
              </p>
            ) : null}
          </div>
        </div>

        <Modal
          open={this.state.filterModalVisible}
          onClose={this.closeFilterModal}
          style={{ width: "900px" }} // Set the width of the Modal to 600px
        >
          <Modal.Header>Filter Options</Modal.Header>
          <Modal.Content>
            <Row>
              <Col span={12}>
                <label>Select Dataset:</label>
                {this.state.options ? (
                  <Select
                    onChange={this.handleRCPChange}
                    placeholder="Select Datasets for Analysis"
                    options={this.state.options.map((opt) => ({
                      label: opt.value, // Display text
                      value: opt.key, // Actual value sent to handleRCPChange
                    }))}
                    style={{ width: "100%" }}
                  />
                ) : null}
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={18}></Col>

              <Col span={6}>
                <Button
                  key="cancel"
                  className="cancel-btn"
                  onClick={this.closeFilterModal}
                >
                  Cancel
                </Button>
                <Button
                  className="apply-btn"
                  key="apply"
                  type="primary"
                  onClick={this.applyFilter}
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.downloadModalVisible}
          onClose={this.closeDownloadModal}
          style={{ width: "600px" }} // Set the width of the Modal to 600px
        >
          <Modal.Header>Download Options</Modal.Header>
          <Modal.Content>
            {this.state.loading ? ( // Show loader if loading is true
              <div
                className="map_loader"
                style={{ textAlign: "center", marginTop: "20px" }}
              >
                <Spin tip="Downloading map... Please wait ..." size="large" />
              </div>
            ) : (
              <>
                <>
                  <Row>
                    {this.props.download_map && this.props.download_map.url ? (
                      <a
                        href={this.props.download_map.url}
                        style={{ float: "right", marginLeft: "1%" }}
                      >
                        <Button className="download-data-btn">
                          Download Excel
                        </Button>
                      </a>
                    ) : (
                      <Button
                        className="download-data-btn"
                        onClick={this.handleDownload}
                      >
                        Download Data
                      </Button>
                    )}
                    <Button
                      className="download-map-btn"
                      onClick={this.handleExportImage}
                    >
                      Download Map
                    </Button>
                  </Row>
                </>

                <Row>
                  <Col span={20}></Col>

                  <Col span={4}>
                    <Button
                      className="cancel-btn"
                      key="cancel"
                      onClick={this.closeDownloadModal}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    map_response: state.locus.map_response,
    download_map: state.locus.download_map,
    download_map_value: state.locus.download_map_value,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generateMap: (formdata) => {
      dispatch(locus.generateMap(formdata));
    },
    MapValue: (formdata) => {
      dispatch(locus.MapValue(formdata));
    },
    downloadMap: (formdata) => {
      return dispatch(locus.downloadMap(formdata));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DownloadMap);
