import React, { Component } from "react";

import {
  Header,
  Icon,
  Menu,
  Label,
  Button,
  Grid,
  Radio,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Dropdown,
} from "semantic-ui-react";

import "./location.css";
import { loadModules } from "esri-loader";
import { CSVReader } from "react-papaparse";
import logo from "../assets/logo.png";
import home from "../assets/home.png";
import add from "../assets/images/add.png";
import sample from "../assets/image.png";
import ll1 from "../assets/logo_land.png";
import search from "../assets/search.png";
import { Accordion, Card } from "react-bootstrap";

import upload from "../assets/upload.png";
import hsi from "../assets/hsi.png";
import sp from "../assets/sp.png";
import dax from "../assets/dax.png";
import dawjones from "../assets/dowjones.png";
import nasdaq from "../assets/nasdaq.png";
import euro from "../assets/euro.png";
import nifty from "../assets/nifty.png";
import nikkie from "../assets/nikkei.png";

import toastr from "toastr";

import { connect } from "react-redux";
import { locus, auth } from "../actions";
import { Checkbox, Typography, Select } from "antd";

import { company } from "../actions";
import Suggestion from "./suggestion";
import PortfolioSpinner from "./loader";
import LocationMap from "./location_map";
import "toastr/build/toastr.min.css";
import { CSVLink } from "react-csv";
import { changeTitle } from "../redux/actions";
import { Redirect } from "react-router-dom";
import { Tabs, Tab, Row, Col, Breadcrumb, Container } from "react-bootstrap";
import video from "../assets/video.mp4";
import video1 from "../assets/video.gif";

import TransitionTable from "./transitiontable";

import { Table } from "antd";

const currentDate = new Date().toISOString().slice(0, 10);

// Concatenate current date with the filename
const filename = `intensel-tempalate-input-csv-file-_${currentDate}.csv`;

const buttonRef = React.createRef();

const companyRef = React.createRef();

let mapcards = [];
let companycards = [];
let selectList = "";
let optionList = [];
let newmapcards = [];
let csvcards = [];
let buildingcsvcards = [];
let companyLocationCards = [];
let selectedList = [];

const othersOptions = [
  { key: "Flood", value: "River Flood", text: "Flood" },
  { key: "Rainfall", value: "Rainfall", text: "Rainfall" },

  { key: "Drought Index", value: "Drought Index", text: "Drought Index" },
  //{ key: "Landslide", value: "Landslide", text: "Landslide" },
  { key: "Storm Surge", value: "Storm Surge", text: "Storm Surge" },

  { key: "Extreme heat", value: "Extreme heat", text: "Extreme heat" },
  { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
];

let minedata = [];

const csvData = [
  [
    "name",
    "latitude",
    "longitude",
    "Valuation (in US$/sqm)",
    "Total Valuation (in US$)",
    "Replacement Value (US$/sqm )",
    "Revenue (in mil US$/year)",
    "Content Value (in US$/sqm)",
    "Total Content Value (in US$)",
    "Ownership (0 to 100)",
    "Property Type",
    "Area (in sqm)",
    "Gross Area (in sqm)",

    //"Total Valuation (in US$)",
    "Number of Floors",

    "Basement (yes,no)",
    //"Price per Kwh(in US$)",
    "Flood Mitigation (low-1,med-2,high-3,no-0)",
    "Exclude Ground Floor (yes,no)",
    /*"Solar Power Production (in gwh)",
    "Wind Power Production (in gwh)",
    "Hydro Power Production (in gwh)",
    "Wheat Production (in kg)",
    "Rice Production (in kg)",
    "Maize Production (in kg)",
    "Fruit Production (in kg)",
    "Vegetable Production (in kg)",*/
  ],
  [
    "intensel",
    "0.00",
    "0.00",

    "0",
    "",
    "0",
    "0",
    "0",
    "",
    "0",
    "commercial",
    "0",
    "",
    "0",

    "yes",
    "no",
    "0",

    "no",
    /*"0",
    "0",
    "0",
    "0",
    "0",
    "0",
    "0",
    "0",*/
  ],
];
let company_card = [];

const ContinentOptions = [
  { key: "Asia", value: ["Asia", 100.6197, 34.04, 3], text: "Asia" },
  { key: "Europe", value: ["Europe", 15.2551, 54.526, 3], text: "Europe" },
  {
    key: "Australia",
    value: ["Australia", 133.7751, -25.2744, 3],
    text: "Australia",
  },
  { key: "America", value: ["America", -95.7129, 37.0902, 2], text: "America" },
  { key: "Africa", value: ["Africa", 34.5085, 8.7832, 3], text: "Africa" },
];
const unitOptions = [
  { key: "meter", value: "meter", text: "meter" },
  { key: "inches", value: "inches", text: "inches" },
];

const indexOptions = [
  { key: "HSI", value: "HSI", text: "HSI" },
  { key: "S&P-500", value: "S&P-500", text: "S&P-500" },
  { key: "dax", value: "dax", text: "dax" },
  { key: "dowjones", value: "dowjones", text: "dowjones" },
  { key: "nasdaq", value: "nasdaq", text: "nasdaq" },
  { key: "eurostock", value: "eurostock", text: "eurostock" },
  { key: "nifty", value: "nifty", text: "nifty" },
  { key: "nikkie", value: "nikkie", text: "nikkie" },
];
let searchList = [];

const SearchOptions = [
  { key: "HTML Search", value: "html", text: "HTML Search" },
  { key: "Shallow Search", value: "shallow", text: "Shallow Search" },
  { key: "Deep Search", value: "deep", text: "Deep Search" },
];

class Location extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  state = {
    upload: "",
    index: "",
    file: false,
    locations: [],
    query: "",
    farmVariables: [],
    energyVariables: [],
    option: "",
    variables: [],
    buildingVariables: [],
    overwrite: "false",
    company: [],
    portfolio: "",
    selectedOption: null,
    newlocations: [],
    modalOpen: false,
    videoOpen: false,
    loading: false,
    long: 34.0,
    lat: 39.0,
    zoom: 1,
    continent: "Continents",
    selectedTags: [],
    allTags: [],
    buildingselectedTags: [],
    allbuildingTags: [],
    optional: "",
    infoOpen: false,
    buildinginfoOpen: false,
    restrictOpen: false,
    address: "",
    companyloading: false,
    search_loading: false,
    buildingmodalOpen: false,
    search_type: "",
    index: "HSI",
    modalIndicesOpen: false,
    portfolio_value: "",
    portfolio_name: "",
    modalCompanyOpen: false,
    modalFileOpen: false,
    csv: "",
    unit: "",
  };
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };
  handleIndex = (e) => {
    console.log("checked", e.target.id);

    if (this.state.index == e.target.id) {
      this.setState({ index: "" });
    } else {
      this.setState({ index: e.target.id }, () =>
        this.props.getIndices(this.state.index)
      );
    }
  };
  handleFileChange = (e) => {
    console.log("csv", e.target.files[0]);
    this.setState({ csv: e.target.files[0] });
  };
  handleDeleteMarker = (id) => {
    // Your delete marker logic in the parent component
    console.log(`Deleting marker with ID: ${id}`);
  };
  handleChange = (e, { value }) => {
    this.setState({ selectedOption: value }, () =>
      console.log("selectedoption", this.state.selectedOption)
    );
  };
  onSubmit = (e) => {
    e.preventDefault();
    let defaultvar = ["River Flood", "Typhoon", "Storm Surge"];
    let variables = defaultvar.concat(this.state.variables);
    console.log("variables", variables, this.state.variables);
    let formdata = new FormData();

    formdata.append("data", JSON.stringify(this.state.locations));
    //formdata.append("unit", this.state.unit);
    formdata.append("portfolio_name", this.state.portfolio);
    formdata.append("portfolio_variables", JSON.stringify(variables));
    formdata.append(
      "non_building_variables",
      JSON.stringify(this.state.buildingVariables)
    );
    console.log("final data", formdata.get("location"));
    this.setState({ loading: true }, () => {
      this.props.addLocations(formdata);
    });
  };
  onCompanySubmit = (e) => {
    e.preventDefault();
    let defaultvar = [
      "River Flood",
      "Typhoon",
      "Storm Surge",
      "Wild Fire",
      "Landslide",
      "Rainfall Flood",
      "Extreme Heat",
      "Snow Melt",
      "Sea Level Rise",
      "Drought",
    ];
    let variables = defaultvar.concat(this.state.variables);
    console.log("variables", variables, this.state.variables);
    let formdata = new FormData();

    formdata.append("data", JSON.stringify(this.state.locations));
    formdata.append("is_benchmark", true);
    formdata.append("ticker", this.state.company);
    formdata.append("index_name", this.state.index);
    formdata.append("portfolio_name", this.state.portfolio);
    formdata.append("portfolio_variables", JSON.stringify(variables));
    formdata.append(
      "non_building_variables",
      JSON.stringify(this.state.buildingVariables)
    );
    console.log("final data", formdata.get("location"));
    this.setState({ loading: true }, () => {
      this.props.addCompanyLocations(formdata);
    });
  };
  onFileSubmit = (e) => {
    e.preventDefault();
    let defaultvar = ["River Flood", "Typhoon", "Storm Surge"];
    let variables = defaultvar.concat(this.state.variables);
    console.log("variables", variables, this.state.variables);
    let formdata = new FormData();

    formdata.append("upload_file", this.state.csv);

    formdata.append("portfolio_name", this.state.portfolio);
    formdata.append("unit", this.state.unit);

    formdata.append("portfolio_variables", JSON.stringify(variables));
    formdata.append(
      "non_building_variables",
      JSON.stringify(this.state.buildingVariables)
    );
    console.log("final data", formdata.get("location"));
    this.setState({ loading: true }, () => {
      this.props.addLocations(formdata);
    });
  };
  handleUnitChange = (value) => {
    this.setState({ unit: value });
  };
  onIndicesSubmit = (e) => {
    e.preventDefault();

    let formdata = new FormData();

    formdata.append("index_name", this.state.index);
    formdata.append("portfolio_name", this.state.portfolio);

    this.setState({ loading: true }, () => {
      this.props.addIndex(formdata);
    });
  };
  onbuildingSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("value", JSON.stringify(this.state.locations));
    formdata.append("portfolio_name", this.state.portfolio);
    formdata.append("variables", JSON.stringify(this.state.variables));
    this.setState({ loading: true }, () => {
      this.props.addBuildingLocations(formdata);
    });
  };

  handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleFileLoad = (data) => {
    csvcards = [];
    for (let i = 1; i < data.length - 1; i++) {
      if (data[i].data[3] == "") {
        data[i].data[3] = "NA";
      }
      if (data[i].data[5] == "") {
        data[i].data[5] = "NA";
      }
      if (
        data[i].data[4] == "" ||
        !this.state.optional.includes("Construction Cost")
      ) {
        data[i].data[4] = "NA";
      }
      if (data[i].data[6] == "" || !this.state.optional.includes("Area")) {
        data[i].data[6] = "NA";
      }
      if (data[i].data[7] == "" || !this.state.optional.includes("Valuation")) {
        data[i].data[7] = "NA";
      }
      if (data[i].data[8] == "" || !this.state.optional.includes("Floors")) {
        data[i].data[8] = "NA";
      }
      if (data[i].data[10] == "" || !this.state.optional.includes("Solar")) {
        data[i].data[10] = "NA";
      }
      if (data[i].data[11] == "" || !this.state.optional.includes("Wind")) {
        data[i].data[11] = "NA";
      }
      if (data[i].data[12] == "" || !this.state.optional.includes("Hydro")) {
        data[i].data[12] = "NA";
      }
      if (data[i].data[13] == "" || !this.state.optional.includes("Wheat")) {
        data[i].data[13] = "NA";
      }
      if (data[i].data[14] == "" || !this.state.optional.includes("Rice")) {
        data[i].data[14] = "NA";
      }
      if (data[i].data[15] == "" || !this.state.optional.includes("Maize")) {
        data[i].data[15] = "NA";
      }
      if (data[i].data[16] == "" || !this.state.optional.includes("Fruit")) {
        data[i].data[16] = "NA";
      }
      if (data[i].data[17] == "" || !this.state.optional.includes("Veg")) {
        data[i].data[17] = "NA";
      }
      if (data[i].data[9] == "" || !this.state.optional.includes("Ownership")) {
        data[i].data[9] = "NA";
      }

      csvcards.push({
        name: data[i].data[0],
        latitude: data[i].data[1],
        longitude: data[i].data[2],
        revenue: data[i].data[3],
        property_type: data[i].data[5],
        country_code: "NA",
        construction_cost: data[i].data[4],
        area: data[i].data[6],
        valuation: data[i].data[7],
        stories: data[i].data[8],
        solar_production: data[i].data[10],
        hydro_production: data[i].data[12],
        wind_production: data[i].data[11],
        wheat_production: data[i].data[13],
        rice_production: data[i].data[14],
        maize_production: data[i].data[15],
        fruit_production: data[i].data[16],
        vegetable_production: data[i].data[17],
        ownership: data[i].data[9],
      });
    }
    this.setState({ allTags: csvcards, infoOpen: false }, () =>
      console.log("multiple", this.state.allTags)
    );
  };

  handleCompanyDialog = (e) => {
    if (companyRef.current) {
      companyRef.current.open(e);
    }
  };

  handelOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOpen = () => this.setState({ modalOpen: true });
  handleCompanyOpen = () => this.setState({ modalCompanyOpen: true });
  handleFileOpen = () => this.setState({ modalFileOpen: true });
  handleIndicesOpen = () => this.setState({ modalIndicesOpen: true });
  handlebuildOpen = () => this.setState({ buildingmodalOpen: true });
  handlevideoOpen = () => this.setState({ videoOpen: true });
  handleinfoOpen = () => this.setState({ infoOpen: true });
  handleBuildinginfoOpen = () => this.setState({ buildinginfoOpen: true });
  handleClose = () =>
    this.setState({
      modalOpen: false,
      videoOpen: false,
      infoOpen: false,
      restrictOpen: false,
      buildinginfoOpen: false,
      buildingmodalOpen: false,
      modalIndicesOpen: false,
      modalFileOpen: false,
      modalCompanyOpen: false,
    });

  handleContinent = (e, { value }) => {
    console.log("Continents", value);
    this.setState({
      continent: value[0],
      long: value[1],
      lat: value[2],
      zoom: value[3],
    });
  };

  componentDidMount() {
    console.log("login response", this.props.auth.log_data);
    if (
      this.props.auth.log_data &&
      this.props.auth.log_data.unseen_changelogs &&
      this.props.auth.log_data.unseen_changelogs.length > 0
    ) {
      let toastMessage = "";

      this.props.auth.log_data.unseen_changelogs.forEach((changelog) => {
        toastMessage += `${changelog.title}: ${changelog.description}\n`;
      });

      toastr.options = {
        positionClass: "toast-top-right",
        tapToDismiss: false,
        progressBar: true,
        closeButton: true,
        disableTimeOut: true,
        timeOut: 0,
        onclick: () => {
          window.location.href = "/changelog"; // Redirect to /changelog on click
        },
        onCloseClick: this.props.Notification,
      };

      toastr.info(toastMessage);
    }
  }

  componentWillUnmount() {
    if (this.view) {
      this.view.destroy();
    }
    mapcards = [];
  }

  handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  handleRemoveLocation = (index) => {
    var array = [...this.state.locations];
    console.log("index", index);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ locations: array });
      mapcards.splice(index, 1);
    }
  };

  handleRemoveNewLocation = (index) => {
    var array = [...this.state.newlocations];
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ newlocations: array });
      newmapcards.splice(index, 1);
    }
  };
  handleSearch = (e, { value }) => {
    console.log("company selectio", value);
    for (let i = 0; i < value.length; i++) {
      this.setState({ company: value[i] }, () => {
        this.props.getCompanyLocations(this.state.company);
        console.log("companies", value);
        setTimeout(() => {
          this.handleAsync();
        }, 1000);
      });
    }
  };
  handleSearchType = (e, { value }) => {
    this.setState({ search_type: value });
  };
  handleAsync() {
    console.log("calling", this.props.company_location.hits);
    if (this.props.company_location.hits) {
      for (let i = 0; i < this.props.company_location.hits.length; i++) {
        company_card.push({
          name: this.props.company_location.hits[i].document.address_data,
          latitude: this.props.company_location.hits[i].document.latitude,
          longitude: this.props.company_location.hits[i].document.longitude,
        });
      }
      this.setState({ locations: company_card });
    }

    console.log("result of search", this.props.company_location.grouped_hits);
  }

  handleAddress = (e) => {
    let formdata = new FormData();
    formdata.append("company_name", this.state.address);

    this.setState({ companyloading: true }, () => this.props.getName(formdata));
  };

  handleOptions = (e) => {
    if (e.target.value.length > 0) {
      this.setState({ search_loading: true, query: e.target.value }, () =>
        this.props.getCompanyName(this.state.query)
      );

      console.log("search ", this.props.company_name);
      if (this.props.company_name.grouped_hits) {
        console.log("name", this.props.company_name.grouped_hits);

        for (let i = 0; i < this.props.company_name.grouped_hits.length; i++) {
          searchList.push({
            key: this.props.company_name.grouped_hits[i].hits[0].document
              .ticker,
            text:
              this.props.company_name.grouped_hits[i].hits[0].document
                .company_name +
              "(" +
              this.props.company_name.grouped_hits[i].hits[0].document.ticker +
              ")",
            value: this.props.company_name.grouped_hits[i].hits[0].document
              .ticker,
          });
        }

        this.setState(
          {
            option: searchList,
            search_loading: false,
            index: this.props.company_name.grouped_hits[0].hits[0].document
              .index,
          },
          () => console.log("option", this.state.option)
        );
      }
    }

    /* this.setState({ query: e.target.value }, () =>
        console.log("query", this.state.query)
      );*/
  };
  /*handleOptions =(e)=>{
  	
    if(e.target.value.length>1){
      this.selectList=companyList.filter(company=>company.NAME.slice(0,2).toLowerCase()==e.target.value.slice(0,2).toLowerCase())
    	
      let searchList = this.selectList.map(({NAME}) =>{
  return{
    key:NAME,
    value:NAME,
    text:NAME
  }
})      
    	
      this.setState({option:searchList},()=>console.log("option",this.state.option));
    	
    }
  	
          this.setState({query:e.target.value},()=>console.log(this.state.query))

  }*/

  handleLogout = () => {
    this.props.logout();
  };
  onChange = (checkedValues) => {
    console.log("checked", checkedValues);
    this.setState({ variables: checkedValues });
  };
  onFarmChange = (checkedValues) => {
    this.setState({ farmVariables: checkedValues });
  };
  onEnergyChange = (checkedValues) => {
    this.setState({ energyVariables: checkedValues });
  };
  onBuildingChange = (checkedValues) => {
    let energyBuildings = [
      "solar production",
      "wind production",
      "hydro production",
    ];
    let farmCrops = [
      "wheat production",
      "vegetable production",
      "maize production",
      "fruit production",
      "rice production",
    ];

    let energyBuildingValues = checkedValues.filter((value) =>
      energyBuildings.includes(value)
    );
    let farmCropsValues = checkedValues.filter((value) =>
      farmCrops.includes(value)
    );

    this.setState(
      {
        buildingVariables: {
          energy: energyBuildingValues,
          agriculture: farmCropsValues,
        },
      },
      () => console.log("building", this.state.buildingVariables)
    );
  };

  handleOptional = (checkedValues) => {
    this.setState({ optional: checkedValues }, () =>
      console.log("optional", this.state.optional)
    );
  };
  handleCompanyLoad = (data) => {
    console.log("filename", data[1].data[0]);
    for (let i = 1; i < 3; i++) {
      this.props.getCoordinates(data[i].data[0]);

      if (this.props.point.type) {
        minedata.push(this.props.point.features[i].geometry.coordinates);
        console.log("mine data", minedata);
      }

      /*	let LocationList = companyList.filter(company=>company.NAME==data[i].data[0])
        	
          console.log("Location company",LocationList,data[i].data[0]);
        	
            console.log("chal raha hai ",LocationList[0].ADDRESS)
            if(LocationList[0].ADDRESS.length>0){
            let a = JSON.parse(LocationList[0].ADDRESS.replace(/'/g,'"'));
            console.log("working",a);
            let b = JSON.parse(LocationList[0].coord.replace(/'/g,'"'));
            console.log("working",b[0].split(',')[1].replace(/[()]/g,''));
          	
            for(let j=0;j<b.length;j++){
              mapcards.push([a[j],b[j].split(',')[0].replace(/[()]/g,''),b[j].split(',')[1].replace(/[()]/g,''),"NA","NA"])
            }
            this.setState({locations:mapcards},()=>console.log("csvcomapnay",mapcards))
            }*/
    }
  };
  handleMapDataChange = (newMapData) => {
    console.log("map", newMapData);
    this.setState({ locations: newMapData });
  };
  handleAsset = (address, latitude, longitude) => {
    mapcards.push({
      name: address,
      latitude: latitude,
      longitude: longitude,
    });
    this.setState({ locations: mapcards }, () =>
      console.log("location map", this.state.locations, mapcards.length)
    );
  };
  handleClear = () => {
    companycards = [];
    this.setState({ locations: [], address: "" });
  };

  addtoSelected(asset) {
    selectedList.push(asset);
    var array = [...this.state.allTags];
    var index = array.indexOf(asset);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ allTags: array });
    }
    this.setState({ selectedTags: selectedList, locations: selectedList });
  }
  remove(asset) {
    var array = [...this.state.selectedTags];
    var index = array.indexOf(asset);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ selectedTags: array, locations: array }, () =>
        console.log("remove", this.state.selectedTags)
      );
    }
    this.state.allTags.push(asset);
  }
  addAll = () => {
    var array = [...this.state.allTags];
    this.setState({ selectedTags: array, locations: array });
  };
  clearAll = () => {
    this.setState({ selectedTags: "", locations: "", allTags: "" });
  };
  handleLocation = () => {
    company_card = [];
    this.setState(
      { locations: "" },
      console.log("tab change", this.state.locations)
    );
  };

  addtobuildingSelected(asset) {
    selectedList.push(asset);
    var array = [...this.state.allbuildingTags];
    var index = array.indexOf(asset);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ allbuildingTags: array });
    }
    this.setState({
      buildingselectedTags: selectedList,
      locations: selectedList,
    });
  }
  buildingremove(asset) {
    var array = [...this.state.buildingselectedTags];
    var index = array.indexOf(asset);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ buildingselectedTags: array, locations: array }, () =>
        console.log("remove", this.state.buildingselectedTags)
      );
    }
    this.state.allbuildingTags.push(asset);
  }
  addbuildingAll = () => {
    var array = [...this.state.allbuildingTags];
    this.setState({ buildingselectedTags: array, locations: array });
  };
  clearbuildingAll = () => {
    this.setState({
      buildingselectedTags: "",
      locations: "",
      allbuildingTags: "",
    });
  };

  render() {
    const { Text, Link } = Typography;

    const { activeIndex, others } = this.state;

    if (this.props.point.type) {
      minedata.push([
        this.props.point.features[0].place_name,
        this.props.point.features[0].geometry.coordinates[0],
        this.props.point.features[0].geometry.coordinates[1],
      ]);
      console.log("minedata", minedata);
    }
    if (company_card.length > 0) {
      companyLocationCards = [];
      console.log("company card", company_card);
      for (let i = 0; i < company_card.length; i++) {
        companyLocationCards.push(
          <div className="table__row">
            {company_card[i].name ? (
              <div
                className="table__cell"
                style={{ fontWeight: "bold", color: "black" }}
              >
                {i}
                <Icon name="map marker alternate" style={{ color: "blue" }} />
                {company_card[i].name.slice(0, 20)}
              </div>
            ) : null}

            {company_card[i].latitude ? (
              <div className="table__cell">
                {company_card[i].latitude.toFixed(3)}
              </div>
            ) : null}
            {company_card[0].longitude ? (
              <div className="table__cell">
                {company_card[i].longitude.toFixed(3)}
              </div>
            ) : null}
          </div>
        );
      }
    }

    if (this.props.location.state) {
      let newmapcards = this.props.location.state.assets.assets;

      var newcards = [];

      if (newmapcards.length > 0) {
        this.state.newlocations = newmapcards;

        console.log("this.s", this.state.newlocations);
        for (let i = 0; i < this.state.newlocations.length; i++) {
          newcards.push(
            <Grid.Column width="2" className="cont">
              <Label className="card">
                <div className="front">
                  <div className="img-cont">
                    <Image
                      src={home}
                      alt=""
                      style={{ float: "center" }}
                      verticalAlign="middle"
                    />
                  </div>
                  <div className="content-cont">
                    <p
                      style={{
                        textAlign: "center",
                        color: "#015edc",
                        fontSize: "12px",
                      }}
                    >
                      {this.state.newlocations[i].name}
                    </p>
                  </div>
                </div>
                <div className="back">
                  <button
                    style={{
                      float: "right",
                      backgroundColor: "white",
                      border: "0px",
                      fontSize: "10px",
                      color: "grey",
                      marginLeft: "55%",
                    }}
                    onClick={() => this.handleRemoveNewLocation(i)}
                  >
                    <Image
                      src={search}
                      style={{ float: "right", padding: "8px", opacity: "0.5" }}
                      color="grey"
                      size="mini"
                    />
                  </button>

                  <p
                    style={{
                      textAlign: "center",
                      color: "#015edc",
                      fontSize: "12px",
                    }}
                  >
                    <Icon
                      name="map marker alternate"
                      style={{ color: "#015edc" }}
                      size="large"
                    />
                    <br />
                    Lat {this.state.newlocations[i].latitude}
                    <br />
                    Long {this.state.newlocations[i].longitude}
                  </p>
                </div>
              </Label>
            </Grid.Column>
          );
        }
      }
    }

    const { value, continent } = this.state;
    var cards = [];
    var csvtags = [];
    var buildingcsvtags = [];
    console.log("location map render", this.state.locations, mapcards);
    if (mapcards.length > 0) {
      console.log("mapcardsssssssss", this.state.locations);
      for (let i = 0; i < this.state.locations.length; i++) {
        cards.push(
          <div className="table__row">
            <div
              className="table__cell"
              style={{ fontWeight: "bold", color: "black" }}
            >
              {i + 1}{" "}
              <Icon name="map marker alternate" style={{ color: "blue" }} />
              {this.state.locations[i].name.slice(0, 20)}
            </div>

            <div className="table__cell">
              {this.state.locations[i].latitude.toFixed(3)}
            </div>
            <div className="table__cell">
              {this.state.locations[i].longitude.toFixed(3)}
            </div>
            <div className="table__cell">
              <Icon
                style={{ paddingLeft: "10%", cursor: "pointer" }}
                name="trash alternate"
                onClick={() => {
                  this.handleDeleteMarker(i);

                  this.handleRemoveLocation(i);
                }}
              ></Icon>
            </div>
          </div>
        );
      }
    }

    console.log("props", this.props.name);
    if (this.props.name.length > 0 && this.state.address.length > 0) {
      this.state.companyloading = false;
      this.state.locations = [];
      companycards = [];
      for (let i = 0; i < this.props.name.length; i++) {
        this.state.locations.push({
          name: this.props.name[i].address,
          latitude: this.props.name[i].lat,
          longitude: this.props.name[i].lon,
          revenue: "NA",
          property_type: "NA",
          country_code: "NA",
        });
        let src = "//logo.clearbit.com/";
        companycards.push(
          <Grid.Column width="2" className="cont">
            <Label className="card">
              <div className="front">
                <div className="img-cont">
                  <Image
                    src={src + this.props.name[i].domain}
                    alt=""
                    style={{ float: "center" }}
                    verticalAlign="middle"
                  />
                </div>
                <div className="content-cont">
                  <p
                    style={{
                      textAlign: "center",
                      color: "#18202F",
                      fontSize: "12px",
                    }}
                  >
                    {this.props.name[i].address}
                  </p>
                </div>
              </div>
              <div className="back">
                <button
                  style={{
                    float: "right",
                    backgroundColor: "white",
                    border: "0px",
                    fontSize: "10px",
                    color: "grey",
                    marginLeft: "55%",
                  }}
                  onClick={() => this.handleRemoveLocation(i)}
                >
                  <Image
                    src={search}
                    style={{ float: "right", padding: "8px", opacity: "0.5" }}
                    color="grey"
                    size="mini"
                  />
                </button>

                <p
                  style={{
                    textAlign: "center",
                    color: "#18202F",
                    fontSize: "12px",
                  }}
                >
                  <Icon
                    name="map marker alternate"
                    style={{ color: "#18202F  " }}
                    size="large"
                  />
                  <br />
                  Lat {this.props.name[i].lat}
                  <br />
                  Long {this.props.name[i].lon}
                </p>
              </div>
            </Label>
          </Grid.Column>
        );
      }
      console.log("company location", this.state.locations);
    }

    if (csvcards.length > 0) {
      for (let i = 0; i < this.state.allTags.length; i++) {
        csvtags.push(
          <Label style={{ display: "block" }}>
            {this.state.allTags[i]["name"]}{" "}
            <Icon
              name="plus"
              onClick={() => this.addtoSelected(this.state.allTags[i])}
              style={{ float: "right" }}
            />
          </Label>
        );
      }
    }
    if (buildingcsvcards.length > 0) {
      for (let i = 0; i < this.state.allbuildingTags.length; i++) {
        buildingcsvtags.push(
          <Label style={{ display: "block" }}>
            {this.state.allbuildingTags[i]["name"]}{" "}
            <Icon
              name="plus"
              onClick={() =>
                this.addtobuildingSelected(this.state.allbuildingTags[i])
              }
              style={{ float: "right" }}
            />
          </Label>
        );
      }
    }

    return (
      <div>
        <Container fluid style={{ backgroundColor: "#18202F" }}>
          <Row>
            <br />
            <Breadcrumb style={{ paddingLeft: "0.5%" }}>
              <Breadcrumb.Item>Location</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Container>

        <br />

        <Container fluid style={{ padding: "0.7%" }}>
          <Row className="Location_Content">
            <Col>
              <Tabs
                className="location"
                defaultActiveKey="Asset"
                onSelect={this.handleLocation}
              >
                <Tab title="Asset Location" eventKey="Asset" className="Map">
                  <br />

                  {/*  <Col md={7} sm={12} xs={12} className="mapcol">
                      <p className="toppara">Mark locations to analyse</p>*/}

                  {/*  <Header
                        className="asset"
                        as="h5"
                        style={{ width: "100%" }}
                      >
                        <span className={"dark-mode-primary-color"}>
                          Select location to analyze climate risk on map{" "}
                          <Icon name="map marker alternate" />
                        </span>
                        <Form.Select
                          placeholder={continent}
                          value={continent}
                          onChange={this.handleContinent}
                          options={ContinentOptions}
                          style={{ float: "right", minHeight: "1.7em" }}
                        /></Header>*/}

                  <LocationMap
                    lat={this.state.lat}
                    long={this.state.long}
                    zoom={this.state.zoom}
                    onMapDataChange={this.handleMapDataChange}
                  />

                  {/*  <Col lg={5} className="asset">
                      {/* <Header
                        className="asset"
                        as="h5"
                        style={{ width: "100%" }}
                      >
                        <span className={"dark-mode-primary-color"}>
                          Selected Asset
                        </span>
                      </Header>

                      <div className="table">
                        <div className="table__body">
                          <div className="table__row table__heading">
                            <div className="table__cell">Name</div>
                            <div className="table__cell">Latitude</div>
                            <div className="table__cell">Longitude</div>
                            <div className="table__cell">Delete</div>
                          </div>
                          {cards}
                        </div>
                      </div>
                      <Row>
                        {/* <Button
                        primary
                        onClick={this.handleOpen}
                        style={{
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #355ed814",
                          fontFamily: "montserrat",
                          marginLeft: "3%",
                          borderRadius: "7.20472px",
                          fontSize: "16px",
                          borderRadius: 5,
                          color: "#18202F",
                        }}
                      >
                        Sort By: A - Z
                      </Button>
                      <Button
                        primary
                        onClick={this.handleOpen}
                        style={{
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #355ed814",
                          borderRadius: "7.20472px",
                          fontFamily: "montserrat",
                          fontSize: "16px",
                          borderRadius: 5,
                          color: "#18202F",
                        }}
                      >
                        CLEAR ALL
                      </Button>
                     
                      </Row>

                      <br />
                      {this.props.location.state ? (
                        <div>
                          <Header as="h2" textAlign="left">
                            My Assets
                          </Header>
                          <Grid.Row>{newcards}</Grid.Row>
                        </div>
                      ) : null}
                      <br />
                      <br />
                    </Col>*/}
                  <Button
                    primary
                    onClick={this.handleOpen}
                    style={{
                      fontFamily: "montserrat",
                      fontSize: "15px",
                      borderRadius: 0,
                      marginLeft: "auto",
                      float: "right",
                      backgroundColor: "#355ed814",
                      color: "#18202F",
                    }}
                  >
                    PROCEED
                  </Button>
                </Tab>
                <Tab
                  className="location"
                  title="Multiple Asset CSV"
                  eventKey="Multiple Asset CSV"
                >
                  <br />
                  <Row className="assetcsv">
                    <Col md={8} sm={12} xs={12}>
                      <h3 className="h3assetcsv">
                        <span className={"dark-mode-primary-color"}>
                          Upload CSV <Icon name="file alternate" />
                        </span>
                      </h3>

                      <CSVLink data={csvData} filename={filename}>
                        <Button className="samplecsv">
                          <span className={"dark-mode-primary-color"}>
                            Download Sample CSV
                          </span>
                        </Button>
                      </CSVLink>

                      <Form>
                        <div>
                          <label
                            style={{
                              color: "#18202F",
                              fontSize: "16px",
                            }}
                          >
                            File Input
                          </label>
                          <Input
                            className="filecsv"
                            type="file"
                            ref={(input) => (this.filesInput = input)}
                            name="file"
                            onChange={this.handleFileChange}
                          />
                        </div>
                      </Form>
                      <Button
                        className="uploadcsv"
                        onClick={this.handleFileOpen}
                        primary
                      >
                        Submit
                      </Button>
                    </Col>
                    <Col lg={4}>
                      <div className="notecsv">
                        <h4 className="h3assetcsv">
                          Note(Click on Header to Expand)
                        </h4>
                        <Accordion defaultActiveKey="0">
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey="0"
                              style={{ cursor: "pointer" }}
                            >
                              Instructions for submitting multiple assets on
                              BlueSwan platform
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <ul>
                                  <li>
                                    For submitting multiple assets on the
                                    BlueSwan platform to conduct the physical
                                    climate risk analysis, the user will need to
                                    download the sample CSV file to input data
                                    following the format as prescribed in the
                                    CSV file and upload the completed CSV file.
                                    Essential input data to conduct the analysis
                                    includes asset name and location
                                    information, while optional input data would
                                    be helpful to increase the accuracy of the
                                    amount for Climate Value at Risk (financial
                                    loss in dollar value).
                                  </li>
                                  <li>
                                    For location information, consider the
                                    granularity of analysis provided by the
                                    BlueSwan platform, it is important to input
                                    an accurate location of the asset in the CSV
                                    file. If the user refers to Google Maps for
                                    mapping the asset location, please refer to
                                    the satellite image of the map. Except for
                                    asset location, most input data can be
                                    updated later under the “Asset Table”
                                    Section of the Analysis result Dashboard.
                                  </li>
                                  <li>
                                    For inputting asset location information, it
                                    is observed that there is a shift in
                                    location on Google Maps’ satellite imagery
                                    for an asset located in mainland China.
                                    Special attention has to be paid to
                                    cross-check the location found on Google
                                    Maps with the user’s internal asset location
                                    information or other online map resources
                                    (such as Baidu map, Gaode map, or
                                    open-street map).
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey="1"
                              style={{ cursor: "pointer" }}
                            >
                              Please select appropriate flood risk reduction
                              level from High to Low
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <p>
                                  This function is to account for the flood risk
                                  reduction capacity of three flood mitigation
                                  measures that are near the asset and connect
                                  to the main flood control / stormwater
                                  management system of the area: a) manhole, b)
                                  catchpit, and c) underground flood tunnel.
                                  These mitigation measures are effective to
                                  reduce rainfall and river flooding, and they
                                  include any existing or to-be-built measures.
                                  It does not apply to storm surge flooding, as
                                  when there is a rise in sea level on the
                                  coastal side, the floodwater is very likely to
                                  block most nearby outlets of the stormwater
                                  management system. The level of flood risk
                                  reduction depends on below two key factors and
                                  assumption:
                                  <br />
                                  1. Distance between any mitigation measure and
                                  the asset. To have a risk reduction impact,
                                  the closer the mitigation measure to the
                                  asset, the greater level of risk reduction.
                                  Any mitigation measure that is more than 20
                                  meters far from the asset is assumed to have
                                  no risk reduction effect.
                                  <br />
                                  2. The number of available mitigation
                                  measures. The more the number of mitigation
                                  measures, the greater level of risk reduction.
                                  It can be in any combination of item a, b, and
                                  c as listed above, e.g., asset with two nearby
                                  manholes plus one nearby underground flood
                                  tunnel is counted as a total of 3 measures for
                                  that asset.
                                  <br />
                                  <br />
                                  <b>
                                    {" "}
                                    A. High level of flood risk reduction –
                                    Either:
                                  </b>
                                  <br />
                                  i. Having at least three measures in place
                                  near the asset. Two measures both are at least
                                  within a distance of ≤ 10 meters, plus the
                                  remaining measure is at least within 15-20
                                  meters; or
                                  <br />
                                  ii. Having at least three measures in place
                                  near the asset. Two measures both are at least
                                  within a distance of 10-15 meters, plus the
                                  remaining measure is at least within ≤ 10
                                  meters.
                                  <br />
                                  <br />
                                  <b>
                                    B. Median level of flood risk reduction –
                                    Either:
                                  </b>
                                  <br />
                                  i. Having at least three measures in place.
                                  Two measure both are at least within 15-20
                                  meters, plus the remaining measure is at least
                                  within 10-15 meters; or
                                  <br />
                                  ii. Having at least two measures in place near
                                  the asset. One measure is at least within ≤ 10
                                  meters, plus another one is at least within
                                  15-20 meters; or
                                  <br />
                                  iii. Having at least two measures in place
                                  near the asset, and two measures both are at
                                  least within 10-15 meters.
                                  <br />
                                  <br />
                                  <b>
                                    {" "}
                                    C. Low level of flood risk reduction –
                                    Either:
                                  </b>
                                  <br />
                                  i. Having at least two measures in place, and
                                  these two measures both are at least within
                                  15-20 meters; or
                                  <br />
                                  ii. Having at least one measure in place, and
                                  this measure is at least within a distance of
                                  ≤ 10 meters.
                                  <br />
                                  <br />
                                  Example: An asset has nearby two flood
                                  mitigation measures (no matter what
                                  combination of a) manhole, b) catchpit or c)
                                  underground flood tunnel). One measure (e.g.,
                                  manhole) is within 10-15 meters and another
                                  measure (e.g., catchpit or another manhole) is
                                  at 15-20 meters far from the asset. Both
                                  measures are connected to the city-level
                                  stormwater management system. In this example,
                                  as it can’t fulfill the requirement of the
                                  median level of flood risk reduction of points
                                  B) ii and B) iii above, it falls into the
                                  category of low level of flood risk reduction.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </div>

                      {/* <Button
                  style={{
                    backgroundColor: "#f88b8b",
                    color: "white",
                    float: "right",
                  }}
                  onClick={this.handlevideoOpen}
                >
                  <Icon name="lightbulb" />
                  How to Upload
                </Button>

                    <Button
                      style={{
                        color: "#18202F",
                        backgroundColor: "#f6f8fa",
                        border: "0.5px solid #18202F",
                      }}
                    >
                      <span className={"dark-mode-primary-color"}>
                        <a href={sample}>Download Sample CSV</a>
                      </span>
                    </Button>

                    <br />
                    <br />

                    <CSVReader
                      ref={buttonRef}
                      onFileLoad={this.handleFileLoad}
                      onError={this.handleOnError}
                      noClick
                      noDrag
                    >
                      {({ file }) => (
                        <aside
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderColor: "black",
                              height: 45,
                              lineHeight: 2.5,
                              marginTop: 0,
                              marginBottom: 0,
                              paddingLeft: 5,
                              paddingTop: 3,
                              width: "80%",
                              borderRadius: 0,
                              backgroundColor: "transparent",
                            }}
                          >
                            <Header
                              class="asset"
                              as="h5"
                              style={{ padding: "10px" }}
                            >
                              <span className="dark-mode-primary-color">
                                No file selected
                              </span>
                            </Header>
                            <button
                              style={{
                                float: "right",
                                backgroundColor: "transparent",
                                border: "0px",
                                fontSize: "10px",
                                color: "grey",
                                marginTop: "-50px",
                              }}
                              onClick={this.handleRemoveFile}
                            >
                              <Icon
                                name="close"
                                style={{ color: "white", padding: "8px" }}
                                size="big"
                              />
                            </button>
                          </div>
                          <button
                            type="button"
                            onClick={this.handleOpenDialog}
                            style={{
                              borderRadius: 0,
                              marginLeft: 0,
                              marginRight: 0,
                              width: "10%",

                              paddingRight: 0,
                              border: 0,
                              borderWidth: 1,
                              borderColor: "black",
                              borderStyle: "solid",
                              backgroundColor: "white",
                              color: "grey",

                              height: "45px",
                            }}
                          >
                            <Image
                              src={upload}
                              style={{
                                padding: "10%",
                                width: "30px",
                                height: "30px",
                              }}
                              color="grey"
                              centered
                            />
                          </button>
                        </aside>
                      )}
                    </CSVReader>
                    <br />
                    <br />
                    <Grid.Row>
                      <Grid.Column
                        className="csvbox"
                        style={{ width: "42.5%" }}
                      >
                        <h3>All Assets</h3>
                        <div class="border">{csvtags}</div>
                      </Grid.Column>
                      <Grid.Column
                        style={{ width: "12.5%" }}
                        verticalAlign="middle"
                      >
                        <Button
                          onClick={this.addAll}
                          style={{
                            position: "absolute",
                            top: "60%",
                            padding: "1%",
                            marginLeft: "1%",
                            backgroundColor: "#18202F",
                            color: "white",
                            fontSize: "0.9rem",
                          }}
                        >
                          Add All
                        </Button>
                        <Button
                          onClick={this.clearAll}
                          style={{
                            position: "absolute",
                            top: "65%",
                            padding: "1%",
                            marginLeft: "1%",
                            backgroundColor: "#18202F",
                            color: "white",
                            fontSize: "0.9rem",
                          }}
                        >
                          Clear All
                        </Button>
                      </Grid.Column>
                      <Grid.Column
                        className="csvbox"
                        style={{ width: "42.5%" }}
                      >
                        <h3>Selected Assets</h3>
                        <div class="border">
                          {this.state.selectedTags.length > 0
                            ? this.state.selectedTags.map((asset, index) => (
                                <Label key={index} style={{ display: "block" }}>
                                  {asset["company_name"]}
                                  <Icon
                                    name="close"
                                    onClick={() => this.remove(asset)}
                                  />
                                </Label>
                              ))
                            : null}
                        </div>
                       
                      </Grid.Column>
                        </Grid.Row>*/}
                    </Col>
                  </Row>
                  {/*   <Row>
                    <Col lg={8} sm={12} xs={12}>
                      <h3>
                        <span className={"dark-mode-primary-color"}>
                          Upload CSV <Icon name="file alternate" />
                        </span>
                      </h3>
                      <Button
                        style={{
                          backgroundColor: "#f88b8b",
                          color: "white",
                          float: "right",
                        }}
                        onClick={this.handlevideoOpen}
                      >
                        <Icon name="lightbulb" />
                        How to Upload
                      </Button>

                      <CSVLink data={csvData}>
                        <Button
                          style={{
                            color: "#18202F",
                            backgroundColor: "#f6f8fa",
                            border: "0.5px solid #18202F",
                          }}
                        >
                          <span className={"dark-mode-primary-color"}>
                            Download Sample CSV
                          </span>
                        </Button>
                      </CSVLink>

                      <br />
                      <br />

                      <CSVReader
                        ref={buttonRef}
                        onFileLoad={this.handleFileLoad}
                        onError={this.handleOnError}
                        noClick
                        noDrag
                      >
                        {({ file }) => (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: isDarkMode ? "white" : "black",
                                height: 45,
                                lineHeight: 2.5,
                                marginTop: 0,
                                marginBottom: 0,
                                paddingLeft: 5,
                                paddingTop: 3,
                                width: "80%",
                                borderRadius: 0,
                                backgroundColor: "transparent",
                              }}
                            >
                              <Header
                                class="asset"
                                as="h5"
                                style={{ padding: "10px" }}
                              >
                                <span className="dark-mode-primary-color">
                                  No file selected
                                </span>
                              </Header>
                              <button
                                style={{
                                  float: "right",
                                  backgroundColor: "transparent",
                                  border: "0px",
                                  fontSize: "10px",
                                  color: "grey",
                                  marginTop: "-50px",
                                }}
                                onClick={this.handleRemoveFile}
                              >
                                <Icon
                                  name="close"
                                  style={{ color: "white", padding: "8px" }}
                                  size="big"
                                />
                              </button>
                            </div>
                            <button
                              type="button"
                              onClick={this.handleinfoOpen}
                              style={{
                                borderRadius: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                width: "10%",

                                paddingRight: 0,
                                border: 0,
                                borderWidth: 1,
                                borderColor: isDarkMode ? "white" : "black",
                                borderStyle: "solid",
                                backgroundColor: "white",
                                color: "grey",

                                height: "45px",
                              }}
                            >
                              <Image
                                src={upload}
                                style={{
                                  padding: "10%",
                                  width: "30px",
                                  height: "30px",
                                }}
                                color="grey"
                                centered
                              />
                            </button>
                          </aside>
                        )}
                      </CSVReader>
                      <br />
                      <br />
                      <Grid.Row>
                        <Grid.Column
                          className="csvbox"
                          style={{ width: "42.5%" }}
                        >
                          <h3>All Assets</h3>
                          <div class="border">{csvtags}</div>
                        </Grid.Column>
                        <Grid.Column
                          style={{ width: "12.5%" }}
                          verticalAlign="middle"
                        >
                          <Button
                            onClick={this.addAll}
                            style={{
                              position: "absolute",
                              top: "60%",
                              padding: "1%",
                              marginLeft: "1%",
                              backgroundColor: "#18202F",
                              color: "white",
                              fontSize: "0.9rem",
                            }}
                          >
                            {" "}
                            Add All
                          </Button>
                          <Button
                            onClick={this.clearAll}
                            style={{
                              position: "absolute",
                              top: "65%",
                              padding: "1%",
                              marginLeft: "1%",
                              backgroundColor: "#18202F",
                              color: "white",
                              fontSize: "0.9rem",
                            }}
                          >
                            Clear All
                          </Button>
                        </Grid.Column>
                        <Grid.Column
                          className="csvbox"
                          style={{ width: "42.5%" }}
                        >
                          <h3>Selected Assets</h3>
                          <div class="border">
                            {this.state.selectedTags.length > 0
                              ? this.state.selectedTags.map((asset, index) => (
                                  <Label
                                    key={index}
                                    style={{ display: "block" }}
                                  >
                                    {asset["name"]}
                                    <Icon
                                      name="close"
                                      onClick={() => this.remove(asset)}
                                    />
                                  </Label>
                                ))
                              : null}
                          </div>
                          <Button
                            primary
                            onClick={this.handleOpen}
                            style={{
                              float: "right",
                              fontSize: "16px",
                              borderRadius: 5,
                              backgroundColor: "#18202F",
                              color: "white",
                              marginTop: "22%",
                            }}
                          >
                            SUBMIT
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Col>
                          </Row>*/}
                </Tab>
                {/*<Tab title="Company" eventKey="Company">
                  <Row className="companypage">
                    <Col lg={8} sm={12} xs={12}>
                      {!this.state.companyloading ? (
                        <div>
                          <br />
                          <h3 className="seacrcmp" style={{ width: "100%" }}>
                            <span className={"dark-mode-primary-color"}>
                              Search Company <Icon name="briefcase" />
                            </span>
                          </h3>

                          <Row>
                            <Col lg={9}>
                              <Form.Field
                                id="form-input-control-portfolio"
                                control={Input}
                                value={this.state.address}
                                onChange={(e) =>
                                  this.setState({ address: e.target.value })
                                }
                                placeholder="search company name e.g. intensel"
                              />

                              {this.state.address.length > 0 ? (
                                <Icon
                                  onClick={this.handleClear}
                                  name="close"
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "-7.5%",
                                    marginLeft: "-2%",
                                    opacity: "0.75",
                                  }}
                                />
                              ) : null}
                            </Col>

                            <Row className="dropdwnc1">
                              <Col>
                                <Dropdown
                                  className="dropdwnc2"
                                  placeholder="Select Category"
                                  fluid
                                  selection
                                  options={SearchOptions}
                                  value={this.state.search_type}
                                  onChange={this.handleSearchType}
                                />
                              </Col>

                              <Col>
                                <Button
                                  className="submitbtn"
                                  onClick={this.handleAddress}
                                  primary
                                  style={{
                                    backgroundColor: "#18202F",
                                    marginRight: "1%",
                                  }}
                                >
                                  Search
                                </Button>
                                <Button
                                  className="submitbtn2"
                                  primary
                                  onClick={() => window.location.reload()}
                                  style={{
                                    backgroundColor: "#f4f4f4",
                                    color: "#18202F",
                                    marginLeft: "1%",
                                  }}
                                >
                                  Reset
                                </Button>
                              </Col>
                            </Row>
                          </Row>

                          {/* <Dropdown 
          placeholder="Search For Company"
          fluid
          
          search
          selection 
          value={value}
          onChange={this.handleSearch}
          options={this.state.option}
          onKeyUp={this.handleOptions}
          style={{width:'89%',borderRadius:'0',border:'1px solid',backgroundColor:'#ecf0f3'}}
       />
                          <br />
                          {this.props.name.error ? (
                            <Grid.Row>
                              <p>No Data Found</p>
                            </Grid.Row>
                          ) : (
                            <Grid.Row>{companycards}</Grid.Row>
                          )}
                          {/*<CSVReader
      
        ref={companyRef}
        onFileLoad={this.handleCompanyLoad}
        onError={this.handleOnError}
        noClick
        noDrag
        
      >
        {({ file }) => (
          <aside
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 10

            }}
          >
            
              
             <div
              style={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: isDarkMode?'white':'black',
                height: 45,
                lineHeight: 2.5,
                marginTop: 0,
                marginBottom: 0,
                paddingLeft: 5,
                paddingTop: 3,
                width: '80%',
                borderRadius:0,
                backgroundColor:'transparent',
                
                

              }}
            >
              <Header class="asset" as="h5"  style={{padding:'10px'}}><span className="dark-mode-primary-color">No file selected</span></Header>
             <button style={{float:'right',backgroundColor:'transparent',border:'0px',fontSize:'10px',color:'grey',marginTop:'-50px'}} onClick={this.handleRemoveCompany}><Icon name='close' style={{ color:'white',padding:'8px'}} size="big"/></button>
            </div>
            <button
              type='button'
              onClick={this.handleCompanyDialog}
              style={{
                borderRadius: 0,
                marginLeft:0,
                marginRight: 0,
                width: '10%',
                

                paddingRight: 0,
                borderWidth:1,
                
                backgroundColor:'#18202F',
                color:'grey',
                
                height:'45px'
                
              }}
            >
              <Image src={upload} style={{padding:'10%',width:'30px',height:'30px'}} color="white" centered/>
            </button>
          </aside>
        )}
      </CSVReader>
                          <Button
                            primary
                            onClick={this.handleOpen}
                            style={{
                              float: "right",
                              fontSize: "16px",
                              borderRadius: 5,
                              backgroundColor: "#18202F",
                              color: "white",
                              marginTop: "22%",
                            }}
                          >
                            SUBMIT
                          </Button>
                        </div>
                      ) : (
                        <div id="loading1"></div>
                      )}
                    </Col>
                  </Row>
                </Tab>*/}

                {/*  <Tab title="Company Location" eventKey="company_location">
                  <Row>
                    <Col lg={8}>
                      {" "}
                      <Dropdown
                        placeholder="Search For Company"
                        fluid
                        search
                        multiple
                        selection
                        value={value}
                        onChange={this.handleSearch}
                        options={this.state.option}
                        onKeyUp={this.handleOptions}
                        style={{
                          width: "89%",
                          borderRadius: "0",
                          border: "1px solid",
                          backgroundColor: "#ecf0f3",
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <br />
                  {companyLocationCards && companyLocationCards.length > 0 ? (
                    <>
                      <Row>
                        <Col lg={7} className="company_asset">
                          {" "}
                          <div className="table">
                            <div className="table__body">
                              <div className="table__row table__heading">
                                <div className="table__cell">Name</div>
                                <div className="table__cell">Latitude</div>
                                <div className="table__cell">Longitude</div>
                              </div>
                              {companyLocationCards}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={8}></Col>
                        <Col>
                          <Button
                            primary
                            onClick={this.handleCompanyOpen}
                            style={{
                              float: "right",
                              fontSize: "16px",
                              borderRadius: 5,
                              backgroundColor: "#18202F",
                              color: "white",
                              marginTop: "22%",
                            }}
                          >
                            SUBMIT
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </Tab>*/}
                {/* <Tab title="Mine Address Location" eventKey="My Address">
                  <Row className="mineaddressrow">
                    <Col lg={8} sm={12} xs={12}>
                      <h3>
                        <span
                          className={"dark-mode-primary-color"}
                          id="mineaddress"
                        >
                          Upload CSV (Address) <Icon name="file alternate" />
                        </span>
                      </h3>

                      <CSVReader
                        ref={companyRef}
                        onFileLoad={this.handleCompanyLoad}
                        onError={this.handleOnError}
                        noClick
                        noDrag
                      >
                        {({ file }) => (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                height: 50,
                                lineHeight: 2.5,
                                marginTop: 20,
                                marginBottom: 0,
                                paddingLeft: 5,
                                paddingTop: 3,
                                width: "80%",
                                borderRadius: 5,
                                background: "#f4f4f4",
                              }}
                            >
                              <Header
                                class="asset"
                                as="h5"
                                style={{
                                  padding: "10px",
                                  borderRadius: "20px",
                                }}
                              >
                                <span className="dark-mode-primary-color">
                                  No file selected
                                </span>
                              </Header>
                              <button
                                style={{
                                  float: "right",
                                  backgroundColor: "transparent",
                                  border: "0px",
                                  fontSize: "10px",
                                  color: "grey",
                                  marginRight: "2%",
                                  marginTop: "-50px",
                                }}
                                onClick={this.handleRemoveCompany}
                              >
                                <Icon
                                  name="close"
                                  style={{ color: "#999999", padding: "8px" }}
                                  size="big"
                                />
                              </button>
                            </div>
                            <button
                              id="mineaddressbtn"
                              type="button"
                              onClick={this.handleCompanyDialog}
                            >
                              <Image
                                src={upload}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                color="white"
                                centered
                              />
                            </button>
                          </aside>
                        )}
                      </CSVReader>

                      {minedata.length > 0 ? (
                        <CSVLink data={minedata}>
                          <Button
                            style={{
                              color: "#18202F",
                              backgroundColor: "#f6f8fa",
                              border: "0.5px solid #18202F",
                              borderRadius: "20px",
                            }}
                          >
                            <span className={"dark-mode-primary-color"}>
                              Download Data
                            </span>
                          </Button>
                        </CSVLink>
                      ) : null}
                    </Col>
                  </Row>
                </Tab>*/}
                {/*  <Tab title="Non-Building" eventKey="Nonbuilding">
      <h3><span className={"dark-mode-primary-color"}>Upload CSV  <Icon name="file alternate"/></span></h3>
              <Button style={{backgroundColor:'#f88b8b',color:'white',float:'right'}} onClick={this.handlevideoOpen}><Icon name="lightbulb"/>How to Upload</Button>
      

      <CSVLink data={csvData}><Button style={{color:'#18202F',backgroundColor:'#f6f8fa',border:'0.5px solid #18202F'}}><span className={"dark-mode-primary-color"}>Download Sample CSV</span></Button></CSVLink>
      
      <br/>
      <br/>
      
      
       <CSVReader
      
        ref={buildingRef}
        onFileLoad={this.handleBuildingFileLoad}
        onError={this.handleOnError}
        noClick
        noDrag
        
      >
        {({ file }) => (
          <aside
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 10

            }}
          >
            
              
            <div
              style={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: isDarkMode?'white':'black',
                height: 45,
                lineHeight: 2.5,
                marginTop: 0,
                marginBottom: 0,
                paddingLeft: 5,
                paddingTop: 3,
                width: '80%',
                borderRadius:0,
                backgroundColor:'transparent',
                
                

              }}
            >
              <Header class="asset" as="h5"  style={{padding:'10px'}}><span className="dark-mode-primary-color">No file selected</span></Header>
             <button style={{float:'right',backgroundColor:'transparent',border:'0px',fontSize:'10px',color:'grey',marginTop:'-50px'}} onClick={this.handleRemoveFile}><Icon name='close' style={{ color:'white',padding:'8px'}} size="big"/></button>
            </div>
            <button
              type='button'
              onClick={this.handleBuildinginfoOpen}
              style={{
                borderRadius: 0,
                marginLeft:0,
                marginRight: 0,
                width: '10%',
                
                paddingRight: 0,
                border:0,
                borderWidth:1,
                borderColor:isDarkMode?'white':'black',
                borderStyle: 'solid',
                backgroundColor:'white',
                color:'grey',
                
                height:'45px'
                
              }}
            >
              <Image src={upload} style={{padding:'10%',width:'30px',height:'30px'}} color="grey" centered/>
            </button>
          </aside>
        )}
      </CSVReader>
      <br/><br/>
      <Grid.Row>
        
        <Grid.Column className="csvbox" style={{width:'42.5%'}}>
        <h3>All Assets</h3>
        <div class="border">{buildingcsvtags}</div>
        </Grid.Column>
        <Grid.Column style={{width:'12.5%'}} verticalAlign="middle">
        <Button  onClick={this.addbuildingAll} style={{position:'absolute',top:'60%',padding:'1%',marginLeft:'1%',backgroundColor:'#18202F',color:'white',fontSize:'0.9rem'}}> Add All</Button>
        <Button onClick={this.clearbuildingAll} style={{position:'absolute',top:'65%',padding:'1%',marginLeft:'1%',backgroundColor:'#18202F',color:'white',fontSize:'0.9rem'}}>Clear All</Button>
        
        </Grid.Column>
        <Grid.Column  className="csvbox" style={{width:'42.5%'}}>
          <h3>Selected Assets</h3>
         <div class="border" >{this.state.buildingselectedTags.length>0?this.state.buildingselectedTags.map((asset,index)=>(
          <Label key={index} style={{display:'block'}}>{asset['name']}<Icon name="close" onClick={()=>this.buildingremove(asset)}/></Label>)):null}</div>
                 <Button primary onClick={this.handlebuildOpen}style={{float:'right',fontSize:'16px',borderRadius:5,backgroundColor:'#18202F',color:'white',marginTop:'22%'}}>SUBMIT</Button>

        </Grid.Column>

      </Grid.Row>
         </Tab>    */}
                {/* <Tab title="Index Locations" eventKey="index">
                  <Row className="indexlctn">
                    <Col lg={8} sm={12} xs={12}>
                      {this.props.indices.number_of_countries ? (
                        <Row>
                          <h3>Selected Index: </h3>
                          <Row>
                            <div className="inputGroup">
                              <h3>{this.state.index}</h3>
                              <p>
                                Number of Countries:
                                {this.props.indices.number_of_countries}
                              </p>
                              <p>
                                Number of Companies:
                                {this.props.indices.number_of_companies}
                              </p>
                            </div>
                          </Row>
                          <Button
                            primary
                            onClick={this.handleIndicesOpen}
                            style={{
                              float: "right",
                              fontSize: "16px",
                              borderRadius: 5,
                              backgroundColor: "#18202F",
                              color: "white",
                              marginTop: "12%",
                              marginLeft: "30%",
                            }}
                          >
                            SUBMIT
                          </Button>
                        </Row>
                      ) : null}
                      <Row>
                        <h3>Indices:</h3>
                        <form className="form">
                          <Row>
                            <Col lg={3}>
                              <div className="inputGroup">
                                <input
                                  id="hsi"
                                  type="checkbox"
                                  onChange={this.handleIndex}
                                  checked={this.state.index == "hsi"}
                                />
                                <Image src={hsi} size="small" centered />
                                <label for="hsi">HSI</label>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="inputGroup">
                                <input
                                  id="s&p"
                                  type="checkbox"
                                  onChange={this.handleIndex}
                                  checked={this.state.index == "s&p"}
                                />
                                <Image src={sp} size="small" centered />
                                <label for="s&p">S&P-500</label>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="inputGroup">
                                <input
                                  id="dax"
                                  type="checkbox"
                                  onChange={this.handleIndex}
                                  checked={this.state.index == "dax"}
                                />
                                <Image src={dax} size="small" centered />
                                <label for="dax">DAX</label>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="inputGroup">
                                <input
                                  id="dawjones"
                                  type="checkbox"
                                  onChange={this.handleIndex}
                                  checked={this.state.index == "dawjones"}
                                />
                                <Image src={dawjones} size="small" centered />
                                <label for="dawjones">DawJones</label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={3}>
                              {" "}
                              <div className="inputGroup">
                                <input
                                  id="nasdaq"
                                  type="checkbox"
                                  onChange={this.handleIndex}
                                  checked={this.state.index == "nasdaq"}
                                />
                                <Image src={nasdaq} size="small" centered />
                                <label for="nasdaq">NASDAQ</label>
                              </div>
                            </Col>
                            <Col>
                              <div className="inputGroup">
                                <input
                                  id="euro"
                                  type="checkbox"
                                  onChange={this.handleIndex}
                                  checked={this.state.index == "euro"}
                                />
                                <Image src={euro} size="small" centered />
                                <label for="euro">Euro Stock</label>
                              </div>
                            </Col>
                            <Col>
                              <div className="inputGroup">
                                <input
                                  id="nifty"
                                  type="checkbox"
                                  onChange={this.handleIndex}
                                  checked={this.state.index == "nifty"}
                                />
                                <Image src={nifty} size="small" centered />
                                <label for="nifty">Nifty</label>
                              </div>
                            </Col>
                            <Col>
                              <div className="inputGroup">
                                <input
                                  id="nikkie"
                                  type="checkbox"
                                  onChange={this.handleIndex}
                                  checked={this.state.index == "nikkie"}
                                />
                                <Image src={nikkie} size="small" centered />
                                <label for="nikkie">Nikkie</label>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </Row>
                    </Col>
                  </Row>
                          </Tab>*/}
              </Tabs>
            </Col>
          </Row>
        </Container>

        <Modal open={this.state.infoOpen} onClose={this.handleClose} closeIcon>
          <Modal.Header style={{ backgroundColor: "white" }}>
            Confirmation
          </Modal.Header>
          <Modal.Content>
            <p>
              Do you want to upload CSV with these{" "}
              <Label>Optional Input </Label>?
            </p>
            <br />

            <Checkbox.Group
              style={{ paddingLeft: "1%" }}
              onChange={this.handleOptional}
            >
              <Checkbox value="Area"> Area</Checkbox>
              <br />
              <Checkbox value="Valuation"> Valuation</Checkbox>
              <br />
              <Checkbox value="Floors">Number of Floors</Checkbox>
              <Checkbox value="Solar">Solar Power Production (in gwh)</Checkbox>
              <Checkbox value="Wind">Wind Power Production (in gwh)</Checkbox>
              <Checkbox value="Hydro">Hydro Power Production (in gwh)</Checkbox>
              <Checkbox value="Construction Cost">
                Repairing / Construction Cost (in mil $)
              </Checkbox>

              <Checkbox value="Wheat">Wheat Production (in kg)</Checkbox>
              <Checkbox value="Rice">Rice Production (in kg)</Checkbox>
              <Checkbox value="Maize">Maize Production (in kg)</Checkbox>
              <Checkbox value="Fruit">Fruit Production (in kg)</Checkbox>
              <Checkbox value="Veg">Vegetable Production (in kg)</Checkbox>
              <Checkbox value="Ownership">OwnerShip</Checkbox>
            </Checkbox.Group>
            <br />
            <Button
              style={{
                float: "right",
                backgroundColor: "#18202F",
                color: "white",
              }}
              onClick={this.handleOpenDialog}
            >
              Submit
            </Button>
            <br />
            <br />
          </Modal.Content>
        </Modal>

        {/* <Modal
      open={this.state.buildinginfoOpen}
      onClose={this.handleClose}
      closeIcon
      >
      <Modal.Header style={{backgroundColor:'white'}}>Confirmation</Modal.Header>
          <Modal.Content>
            <p>Do you want to upload CSV with these  <Label >Optional Input </Label>?</p><br/>
           
      <Checkbox.Group style={{paddingLeft:'1%'}} onChange={this.handleOptional}>
      <Checkbox value="Area" > Area</Checkbox><br/>
      <Checkbox value="Valuation" > Valuation</Checkbox><br/>
      <Checkbox value="Floors">Number of Floors</Checkbox>
     


      


      </Checkbox.Group><br/>
      <Button style={{float:'right',backgroundColor:'#18202F',color:'white'}} onClick={this.handlebuildingOpen}>Submit</Button>
      <br/>
      <br/>
          </Modal.Content>
     </Modal>*/}
        <Modal
          open={this.state.restrictOpen}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Error
          </Modal.Header>
          <Modal.Content>
            {/*<video autoPlay loop muted id="video">
                <source src={video} type="video/mp4"/>
              </video>*/}
            <p>
              You are only allowed to add{" "}
              {this.props.permissions.assets_allowed} assets for Analysis
            </p>
          </Modal.Content>
        </Modal>
        <Modal open={this.state.videoOpen} onClose={this.handleClose} closeIcon>
          <Modal.Header style={{ backgroundColor: "white" }}>
            How to Upload CSV
          </Modal.Header>
          <Modal.Content>
            {/*<video autoPlay loop muted id="video">
                <source src={video} type="video/mp4"/>
              </video>*/}
            <Image src={video1} />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeIcon
          className="group-wrapper"
        >
          <Modal.Header className="portfolio-header">
            Create Portfolio
          </Modal.Header>
          <Modal.Content
            style={{
              paddingLeft: "0.5rem",
              paddingTop: "0rem",
              paddingBottom: "0rem",
            }}
          >
            <Form>
              {this.props.location.state ? (
                <div>
                  <p style={{ marginLeft: "35%" }}>
                    New Portfolio{" "}
                    <Checkbox
                      label="OverWrite Existing"
                      value={this.state.overwrite}
                      onChange={(e) =>
                        this.setState(
                          { overwrite: !this.state.overwrite },
                          () => console.log("ovewrite", this.state.overwrite)
                        )
                      }
                      toggle
                    />
                  </p>
                  {this.state.overwrite ? (
                    <Form.Field
                      control={Input}
                      label="Portfolio Name"
                      value={this.state.portfolio}
                      onChange={(e) =>
                        this.setState({ portfolio: e.target.value })
                      }
                    />
                  ) : (
                    <Form.Field
                      control={Input}
                      label="Portfolio"
                      defaultValue={this.props.location.state.assets.name}
                      disabled
                    />
                  )}
                </div>
              ) : (
                <div>
                  <Form.Field
                    id="form-input-control-portfolio"
                    control={Input}
                    value={this.state.portfolio}
                    onChange={(e) =>
                      this.setState({ portfolio: e.target.value })
                    }
                    style={{ border: "0px" }}
                    label="Portfolio Name"
                  />

                  {/* <Dropdown  placeholder="Variables for Analysis" fluid multiple  selection selectOnBlur={false} options={othersOptions} value={others} defaultValue={['Flood','Typhoon','Storm Surge']}onChange={(e,{value})=>this.handleChange(value,'others')}/> */}
                  <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "14px",
                      padding: "0px",
                    }}
                  >
                    Select Variables for Analysis
                  </Label>
                  <br />
                  <Text code>
                    * River Flood Typhoon and Storm Surge are default Variables
                  </Text>
                  <br />
                  <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "13px",
                      padding: "0px",
                    }}
                  >
                    Acute
                  </Label>
                  <br />
                  <Checkbox.Group onChange={this.onChange}>
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="1"
                            value="River Flood"
                            defaultChecked
                            disabled
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon1"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  River Flood
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="2"
                            value="Storm Surge"
                            defaultChecked
                            disabled
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon2"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Storm Surge
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="3"
                            value="Typhoon"
                            defaultChecked
                            disabled
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon3"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Typhoon
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                  <br />
                  <Checkbox.Group onChange={this.onChange}>
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="4"
                            value="Wild Fire"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon4"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Wild Fire
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="1"
                            value="Landslide"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon5"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  LandSlide
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="1"
                            value="Rainfall Flood"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon6"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Rainfall Flood
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />

                    <Label
                      style={{
                        backgroundColor: "white",
                        color: "#18202F",
                        fontSize: "13px",
                        padding: "0px",
                      }}
                    >
                      Chronic
                    </Label>
                    <br />
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="7"
                            value="Extreme Heat"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon7"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Extreme Heat
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="8"
                            value="Snow Melt"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon8"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  SnowMelt
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="9"
                            value="Sea Level Rise"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon9"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Sea Level Rise
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="10"
                            value="Drought"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon10"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Drought
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                  <br />
                  {/* <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "13px",
                      padding: "0px",
                    }}
                  >
                    Energy and Farm
                  </Label>
                  <br />

                  <Checkbox.Group onChange={this.onBuildingChange}>
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="11"
                            value="solar production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon11"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Solar Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="12"
                            value="wind production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon12"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Wind Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="13"
                            value="hydro production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon13"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Hydro Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="14"
                            value="wheat production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon14"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Wheat Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="15"
                            value="fruit production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon15"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Fruit Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="16"
                            value="vegetable production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon16"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Vegetable Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="17"
                            value="maize production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon17"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Maize Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="17"
                            value="rice production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon17"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Rice Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                  <br />*/}
                </div>
              )}

              {/* <label>Unit</label>
              <br />
              <Select
                style={{ width: "50%" }}
                placeholder="Select Unit"
                onChange={(value) => this.handleUnitChange(value)}
              >
                <Select.Option value="meter">Meter</Select.Option>
                <Select.Option value="inches">Inches</Select.Option>
              </Select>
                <br />*/}
              <br />

              {this.state.loading && this.props.errors.error == undefined ? (
                <div style={{ marginTop: "-40px" }}>
                  <PortfolioSpinner />
                </div>
              ) : (
                <Button
                  disabled={this.state.portfolio.length < 1}
                  style={{
                    backgroundColor: "#18202F",
                    marginLeft: "40%",
                    color: "white",
                  }}
                  onClick={this.onSubmit}
                  primary
                >
                  Submit
                </Button>
              )}
            </Form>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.modalFileOpen}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header className="portfolio-header">
            Create Portfolio
          </Modal.Header>
          <Modal.Content
            style={{
              paddingLeft: "0.5rem",
              paddingTop: "0rem",
              paddingBottom: "0rem",
            }}
          >
            {this.props.errors.error && this.props.errors.error.length > 0 ? (
              <div>
                {this.props.errors.error.map((errorMessage, index) => (
                  <p key={index} style={{ color: "red" }}>
                    {errorMessage}
                  </p>
                ))}
              </div>
            ) : null}
            <Form>
              {this.props.location.state ? (
                <div>
                  <p style={{ marginLeft: "35%" }}>
                    New Portfolio{" "}
                    <Checkbox
                      label="OverWrite Existing"
                      value={this.state.overwrite}
                      onChange={(e) =>
                        this.setState(
                          { overwrite: !this.state.overwrite },
                          () => console.log("ovewrite", this.state.overwrite)
                        )
                      }
                      toggle
                    />
                  </p>
                  {this.state.overwrite ? (
                    <Form.Field
                      control={Input}
                      label="Portfolio Name"
                      value={this.state.portfolio}
                      onChange={(e) =>
                        this.setState({ portfolio: e.target.value })
                      }
                    />
                  ) : (
                    <Form.Field
                      control={Input}
                      label="Portfolio"
                      defaultValue={this.props.location.state.assets.name}
                      disabled
                    />
                  )}
                </div>
              ) : (
                <div>
                  <Form.Field
                    id="form-input-control-portfolio"
                    control={Input}
                    value={this.state.portfolio}
                    onChange={(e) =>
                      this.setState({ portfolio: e.target.value })
                    }
                    style={{ border: "0px" }}
                    label="Portfolio Name"
                  />

                  {/* <Dropdown  placeholder="Variables for Analysis" fluid multiple  selection selectOnBlur={false} options={othersOptions} value={others} defaultValue={['Flood','Typhoon','Storm Surge']}onChange={(e,{value})=>this.handleChange(value,'others')}/> */}
                  <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "14px",
                      padding: "0px",
                    }}
                  >
                    Select Variables for Analysis
                  </Label>
                  <br />
                  <Text code>
                    * River Flood Typhoon and Storm Surge are default Variables
                  </Text>
                  <br />
                  <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "13px",
                      padding: "0px",
                    }}
                  >
                    Acute
                  </Label>
                  <br />
                  <Checkbox.Group onChange={this.onChange}>
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="1"
                            value="River Flood"
                            defaultChecked
                            disabled
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon1"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  River Flood
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="2"
                            value="Storm Surge"
                            defaultChecked
                            disabled
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon2"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Storm Surge
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="3"
                            value="Typhoon"
                            defaultChecked
                            disabled
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon3"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Typhoon
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                  <br />
                  <Checkbox.Group onChange={this.onChange}>
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="4"
                            value="Wild Fire"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon4"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Wild Fire
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="1"
                            value="Landslide"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon5"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  LandSlide
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="1"
                            value="Rainfall Flood"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon6"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Rainfall Flood
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />

                    <Label
                      style={{
                        backgroundColor: "white",
                        color: "#18202F",
                        fontSize: "13px",
                        padding: "0px",
                      }}
                    >
                      Chronic
                    </Label>
                    <br />
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="7"
                            value="Extreme Heat"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon7"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Extreme Heat
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="8"
                            value="Snow Melt"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon8"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  SnowMelt
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="9"
                            value="Sea Level Rise"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon9"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Sea Level Rise
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="10"
                            value="Drought"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon10"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Drought
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                  <br />
                  {/*  <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "13px",
                      padding: "0px",
                    }}
                  >
                    Energy and Farm
                  </Label>
                  <br />

                  <Checkbox.Group onChange={this.onBuildingChange}>
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="11"
                            value="solar production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon11"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Solar Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="12"
                            value="wind production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon12"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Wind Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="13"
                            value="hydro production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon13"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Hydro Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="14"
                            value="wheat production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon14"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Wheat Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="15"
                            value="fruit production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon15"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Fruit Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="16"
                            value="vegetable production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon16"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Vegetable Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="17"
                            value="maize production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon17"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Maize Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="17"
                            value="rice production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon17"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Rice Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                  <br />*/}
                </div>
              )}

              <label>Unit</label>
              <br />
              {/*   <Select
                style={{ width: "50%" }}
                placeholder="Select Unit"
                onChange={(value) => this.handleUnitChange(value)}
              >
                <Select.Option value="meter">Meter</Select.Option>
                <Select.Option value="inches">Inches</Select.Option>
              </Select>
              <br />
                <br />*/}

              {this.state.loading && this.props.errors.error == undefined ? (
                <div style={{ marginTop: "-40px" }}>
                  <PortfolioSpinner />
                </div>
              ) : (
                <Button
                  disabled={this.state.portfolio.length < 1}
                  style={{
                    backgroundColor: "#18202F",
                    marginLeft: "40%",
                    color: "white",
                  }}
                  onClick={this.onFileSubmit}
                  primary
                >
                  Submit
                </Button>
              )}
            </Form>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.modalCompanyOpen}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header className="portfolio-header">
            Create Portfolio
          </Modal.Header>
          <Modal.Content>
            <Form>
              {this.props.location.state ? (
                <div>
                  <p style={{ marginLeft: "35%" }}>
                    New Portfolio{" "}
                    <Checkbox
                      label="OverWrite Existing"
                      value={this.state.overwrite}
                      onChange={(e) =>
                        this.setState(
                          { overwrite: !this.state.overwrite },
                          () => console.log("ovewrite", this.state.overwrite)
                        )
                      }
                      toggle
                    />
                  </p>
                  {this.state.overwrite ? (
                    <Form.Field
                      control={Input}
                      label="Portfolio Name"
                      value={this.state.portfolio}
                      onChange={(e) =>
                        this.setState({ portfolio: e.target.value })
                      }
                    />
                  ) : (
                    <Form.Field
                      control={Input}
                      label="Portfolio"
                      defaultValue={this.props.location.state.assets.name}
                      disabled
                    />
                  )}
                </div>
              ) : (
                <div>
                  <Form.Field
                    id="form-input-control-portfolio"
                    control={Input}
                    value={this.state.portfolio}
                    onChange={(e) =>
                      this.setState({ portfolio: e.target.value })
                    }
                    style={{ border: "0px" }}
                    label="Portfolio Name"
                  />

                  {/* <Dropdown  placeholder="Variables for Analysis" fluid multiple  selection selectOnBlur={false} options={othersOptions} value={others} defaultValue={['Flood','Typhoon','Storm Surge']}onChange={(e,{value})=>this.handleChange(value,'others')}/> */}
                  {/* <Dropdown  placeholder="Variables for Analysis" fluid multiple  selection selectOnBlur={false} options={othersOptions} value={others} defaultValue={['Flood','Typhoon','Storm Surge']}onChange={(e,{value})=>this.handleChange(value,'others')}/> */}
                  {/* <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "14px",
                      padding: "0px",
                    }}
                  >
                    Select Variables for Analysis
                  </Label>
                  <br />
                  <br />
                  <Text code>
                    * River Flood Typhoon and Storm Surge are default Variables
                  </Text>
                  <br />
                  <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "13px",
                      padding: "0px",
                    }}
                  >
                    Acute
                  </Label>
                  <br />
                  <Checkbox.Group onChange={this.onChange}>
                    <Row>
                      <Col lg={4}>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="1"
                            value="River Flood"
                            defaultChecked
                            disabled
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon1"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  River Flood
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="2"
                            value="Storm Surge"
                            defaultChecked
                            disabled
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon2"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Storm Surge
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="3"
                            value="Typhoon"
                            defaultChecked
                            disabled
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon3"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Typhoon
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                  <br />
                  <Checkbox.Group onChange={this.onChange}>
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="4"
                            value="Wild Fire"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon4"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Wild Fire
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="1"
                            value="Landslide"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon5"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  LandSlide
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="1"
                            value="Rainfall Flood"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon6"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Rainfall Flood
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />

                    <Label
                      style={{
                        backgroundColor: "white",
                        color: "#18202F",
                        fontSize: "13px",
                        padding: "0px",
                      }}
                    >
                      Chronic
                    </Label>
                    <br />
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="7"
                            value="Extreme Heat"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon7"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Extreme Heat
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="8"
                            value="Snow Melt"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon8"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  SnowMelt
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="9"
                            value="Sea Level Rise"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon9"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Sea Level Rise
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="10"
                            value="Drought"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon10"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Drought
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                  <br />
                  <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "13px",
                      padding: "0px",
                    }}
                  >
                    Energy and Farm
                  </Label>
                  <br />

                  <Checkbox.Group onChange={this.onBuildingChange}>
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="11"
                            value="solar production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon11"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Solar Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="12"
                            value="wind production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon12"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Wind Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="13"
                            value="hydro production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon13"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Hydro Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="14"
                            value="wheat production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon14"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Wheat Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="15"
                            value="fruit production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon15"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Fruit Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="16"
                            value="vegetable production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon16"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Vegetable Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div class="variable_card_area">
                          <Checkbox
                            className="variable_checkbox"
                            type="checkbox"
                            id="17"
                            value="maize production"
                          />
                          <div class="single_variable_card">
                            <div class="variable_card_content">
                              <div class="variable_card_icon">
                                <div class="variable_icon variable_icon17"></div>
                              </div>

                              <div class="variable_card_title">
                                <h3>
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                  Maize Production
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Checkbox.Group>*/}
                  <br />
                </div>
              )}

              <br />
              <br />

              {this.state.loading && this.props.errors.error == undefined ? (
                <div style={{ marginTop: "-40px" }}>
                  <PortfolioSpinner />
                </div>
              ) : (
                <Button
                  disabled={this.state.portfolio.length < 1}
                  style={{
                    backgroundColor: "#18202F",
                    marginLeft: "40%",
                    color: "white",
                  }}
                  onClick={this.onCompanySubmit}
                  primary
                >
                  Submit
                </Button>
              )}
            </Form>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.modalIndicesOpen}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header>Create Portfolio</Modal.Header>
          <Modal.Content scrolling>
            <Form>
              {this.props.location.state ? (
                <div>
                  <p style={{ marginLeft: "35%" }}>
                    New Portfolio{" "}
                    <Checkbox
                      label="OverWrite Existing"
                      value={this.state.overwrite}
                      onChange={(e) =>
                        this.setState(
                          { overwrite: !this.state.overwrite },
                          () => console.log("ovewrite", this.state.overwrite)
                        )
                      }
                      toggle
                    />
                  </p>
                  {this.state.overwrite ? (
                    <Form.Field
                      control={Input}
                      label="Portfolio Name"
                      value={this.state.portfolio}
                      onChange={(e) =>
                        this.setState({ portfolio: e.target.value })
                      }
                    />
                  ) : (
                    <Form.Field
                      control={Input}
                      label="Portfolio"
                      defaultValue={this.props.location.state.assets.name}
                      disabled
                    />
                  )}
                </div>
              ) : (
                <div>
                  <Form.Field
                    id="form-input-control-portfolio"
                    control={Input}
                    value={this.state.portfolio}
                    onChange={(e) =>
                      this.setState({ portfolio: e.target.value })
                    }
                    style={{ border: "0px" }}
                    label="Portfolio Name"
                  />

                  {/* <Dropdown  placeholder="Variables for Analysis" fluid multiple  selection selectOnBlur={false} options={othersOptions} value={others} defaultValue={['Flood','Typhoon','Storm Surge']}onChange={(e,{value})=>this.handleChange(value,'others')}/> */}
                </div>
              )}

              <br />
              <br />

              {this.state.loading && this.props.errors.error == undefined ? (
                <div style={{ marginTop: "-40px" }}>
                  <PortfolioSpinner />
                </div>
              ) : (
                <Button
                  disabled={this.state.portfolio.length < 1}
                  style={{
                    backgroundColor: "#18202F",
                    marginLeft: "40%",
                    color: "white",
                  }}
                  onClick={this.onIndicesSubmit}
                  primary
                >
                  Submit
                </Button>
              )}
            </Form>
          </Modal.Content>
        </Modal>

        {/*   <Modal
            open={this.state.buildingmodalOpen}
            onClose={this.handleClose}
            closeIcon
            
          >
            <Modal.Header>Create Portfolio</Modal.Header>
            <Modal.Content scrolling>
                <Form>
        {(this.props.location.state)?
          <div>
            <p style={{marginLeft:'35%'}}>New Portfolio <Checkbox label="OverWrite Existing" value={this.state.overwrite} onChange={e=>this.setState({overwrite:!this.state.overwrite},()=>console.log("ovewrite",this.state.overwrite))} toggle/></p>
            {(this.state.overwrite)?<Form.Field control={Input} label='Portfolio Name' value={this.state.portfolio} onChange={e=>this.setState({portfolio:e.target.value})}/>:<Form.Field control={Input} label='Portfolio' defaultValue={this.props.location.state.assets.name} disabled/>}
          </div>:
          <div>
        <Form.Field 
           id="form-input-control-portfolio"
           control={Input}
           
           value={this.state.portfolio}
           onChange={e=>this.setState({portfolio:e.target.value})}
           style={{border:'0px'}}
           label="Portfolio Name"
           
           />

         <Dropdown  placeholder="Variables for Analysis" fluid multiple  selection selectOnBlur={false} options={othersOptions} value={others} defaultValue={['Flood','Typhoon','Storm Surge']}onChange={(e,{value})=>this.handleChange(value,'others')}/> 
         <Label style={{backgroundColor:'white',color:'#18202F',fontSize:'16px',padding:'0px'}}>Select Variables for Analysis</Label><br/><br/>
         <Checkbox.Group onChange={this.onChange}>
         <Checkbox checked value="solr_production">Solar Production</Checkbox>
         <Checkbox checked value="wind_production">Wind Production</Checkbox>
         <Checkbox checked value="wheat_production">Wheat Production</Checkbox><br/>
         <Checkbox value="rice_production">Rice Production</Checkbox>
         
         <Checkbox value="maize_production">Maize Production</Checkbox>
         <Checkbox value="fruit_production">Fruit Production</Checkbox>
         <Checkbox value="vegetable_production">Vegetable Production</Checkbox>
         
         </Checkbox.Group>
         <br/>
         
         
          </div>
}
        
        
        
        
        
          <br/>
          <br/>

        {(this.state.loading && this.props.errors.error==undefined)?<div style={{marginTop:'-40px'}}><PortfolioSpinner/></div>:
        <Button disabled={this.state.portfolio.length<1} style={{backgroundColor:'#18202F',marginLeft:'40%',color:'white'}} onClick={this.onbuildingSubmit} primary>Submit</Button>}
      </Form>
            </Modal.Content>
</Modal>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.locus.errors,
    locus: state.locus,
    company: state.company.company,
    permissions: state.locus.permissions,
    name: state.locus.name,
    point: state.locus.point,
    indices: state.locus.indices,
    transition: state.locus.transition,
    company_name: state.locus.company_name,
    company_location: state.locus.company_location,
    auth: state.auth,
  };
};
const mapDispatchToPros = (dispatch) => {
  return {
    addLocations: (formdata) => {
      dispatch(locus.addLocations(formdata));
    },
    addCompanyLocations: (formdata) => {
      dispatch(locus.addCompanyLocations(formdata));
    },
    Notification: () => {
      dispatch(locus.Notification());
    },

    addBuildingLocations: (formdata) => {
      dispatch(locus.addBuildingLocations(formdata));
    },
    getCompany: () => {
      dispatch(company.getCompanies());
    },
    logout: () => {
      dispatch(auth.logout());
    },
    changeTitle: (title) => {
      dispatch(changeTitle(title));
    },
    getName: (formdata) => {
      dispatch(locus.getName(formdata));
    },
    getCoordinates: (formdata) => {
      dispatch(locus.getCoordinates(formdata));
    },
    getIndices: (formdata) => {
      dispatch(locus.getIndices(formdata));
    },
    addIndex: (formdata) => {
      dispatch(locus.addIndex(formdata));
    },
    getTransition: (formdata) => {
      dispatch(locus.getTransition(formdata));
    },
    getCompanyName: (formdata) => {
      dispatch(locus.getCompanyName(formdata));
    },
    getCompanyLocations: (formdata) => {
      dispatch(locus.getCompanyLocations(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToPros)(Location);
